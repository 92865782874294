import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@eng-ds/api-client';
import { Observable } from 'rxjs';
import { Ufficio } from 'src/app/pages/bpm-pratiche/quadri/comunicazione/models/uffcio.model';
import { Pratica } from 'src/app/pages/core/models';
import { SelectOption } from 'src/app/shared/form';

@Injectable()
export class UtilityService {
  constructor(private apiClient: ApiClient, private httpClient: HttpClient) {}

  getNazioni(): Observable<SelectOption<string, string>[]> {
    return this.apiClient.request<SelectOption<string, string>[]>('getNazioni');
  }
  getProvince(uuid: string): Observable<SelectOption<string, string>[]> {
    return this.apiClient.request<SelectOption<string, string>[]>(
      'getProvince',
      null,
      { uuid },
      null
    );
  }
  getComuni(uuid: string): Observable<SelectOption<string, string>[]> {
    return this.apiClient.request<SelectOption<string, string>[]>(
      'getComuni',
      null,
      null,
      { uuid }
    );
  }
  getSezioniAmministrazione(): Observable<SelectOption<string, string>[]> {
    return this.apiClient.request<SelectOption<string, string>[]>(
      'getSezioniAmministrazione'
    );
  }
  getEnntiEsterni(): Observable<Ufficio[]> {
    return this.apiClient.request('getEntiEsterni');
  }
  getStatiDelega(): Observable<SelectOption<string, string>[]> {
    return this.apiClient.request<SelectOption<string, string>[]>(
      'getStatiDelega'
    );
  }

  getPraticaDetail(uuid: string): Observable<Pratica> {
    return this.apiClient.request('getPraticaDetail', null, null, { uuid });
  }
}
