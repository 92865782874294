export const IT = {
  TITLE_PAGE_END: ' - Autorità di Sistema Portuale',
  TITLE_PAGE_AGGIORNATA: 'Pratica aggiornata',
  TITLE_PAGE_BOZZA: 'Pratica salvata',
  TITLE_PAGE_0: 'Pratica aggiornata',
  TITLE_PAGE_1: 'Pratica salvata',
  TITLE_PAGE_BUTTON_COMUNICAZIONE_NON_PROC: 'Com. non procedibilità',
  TITLE_PAGE_BUTTON_COMUNICAZIONE_NON_VALIDITA: 'Com. non validità',
  TITLE_PAGE_BUTTON_COMUNICAZIONE_FINALE: 'Com. finale',
  TITLE_PAGE_BUTTON_VERIFICA_PROCEDIBILITA: 'Verifica procedibilità',
  TITLE_PAGE_BUTTON_VERIFICA: 'Verifica preliminare',
  TITLE_PAGE_BUTTON_AVVIO_PROCEDIMENTO: 'Avvio procedimento',
  TITLE_PAGE_BUTTON_APERTURA: 'Apertura pratica',
  TITLE_PAGE_BUTTON_APERTURA_REGISTRO_ACCESSI: 'Apertura Registro Accessi',
  TITLE_PAGE_BUTTON_APERTURA_REG_ACC: 'Apertura Registro Accessi',
  TITLE_PAGE_BUTTON_COMUNICA_PUBBL: 'Comunicazione pubblicazione',
  TITLE_PAGE_BUTTON_CHIUSURA_REG_ACC: 'Chiusura Registro Accessi',
  TITLE_PAGE_BUTTON_ASSEGNA_RESP_PROC: 'Assegna Resp. Proc.',
  TITLE_PAGE_BUTTON_ASSEGNA_RESP_ISTR: 'Assegna Resp. Istr.',
  TITLE_PAGE_BUTTON_ACCETTAZIONE_RESP: 'Accettazione incarico',
  TITLE_PAGE_BUTTON_COMUNICA_RICHIESTA_INTEGRAZIONI:
    'Comunicazione Rich. Integ.',
  TITLE_PAGE_BUTTON_COMUNICA_PREAV_DINIEGO: 'Comunicazione Preav. Din.',
  TITLE_PAGE_BUTTON_INSERISCI_INTEGRAZIONI: 'Inserisci integrazioni',
  TITLE_PAGE_BUTTON_VERIFICA_PREAVVISO: 'Verifica preavviso',
  TITLE_PAGE_BUTTON_VERIFICA_INTEGRAZIONI: 'Verifica integrazioni',
  TITLE_PAGE_BUTTON_COMUNICA_DINIEGO: 'Comunicazione diniego',
  TITLE_PAGE_BUTTON_COMUNICA_BO: 'Comunica con il Responsabile',
  TITLE_PAGE_BUTTON_COMUNICA_FO: "Comunica con l'Operatore",
  TITLE_PAGE_BUTTON_INOLTRO: 'Inoltra pratica',
  TITLE_PAGE_BUTTON_COMUNICA_SG: 'Comunicazione della responsabilità',
  TITLE_PAGE_BUTTON_RINUNCIA: 'Rinuncia',
  TITLE_PAGE_BUTTON_APPROVA_RINUNCIA: 'Approva rinuncia',
  TITLE_PAGE_BUTTON_VERIFICA_INTEGRAZIONE: 'Verifica integrazione',
  TITLE_PAGE_BUTTON_COMUNICA_AUTORIZZAZIONE: 'Comunica autorizzazione',
  TITLE_PAGE_BUTTON_VERIFICA_PAGAMENTO: 'Verifica pagamento',
  TITLE_PAGE_BUTTON_PARERE_ENTE_ESTERNO: 'Parere ente esterno',
  TITLE_PAGE_BUTTON_VALIDAZIONE_ENTI_ESTERNI: 'Validazione parere enti esterni',
  TITLE_PAGE_BUTTON_ISCRIZIONE_REGISTRO: 'Iscrizione registro',
  TITLE_PAGE_BUTTON_ARCHIVIAZIONE_SCIA: 'Archiviazione SCIA',
  TITLE_PAGE_BUTTON_REVOCA_SOSPENSIONE: 'Revoca sospensione',
  TITLE_PAGE_BUTTON_RICHIESTA_RIMBORSO: 'Richiesta rimborso',

  VALIDATIONS_REQUIRED_DOCUMENT: 'Selezionare almeno un documento',
  AUTOCOMPLETE_TRANSLATE_PLACEHOLDER_ALL: 'Tutte',
  VALIDATIONS_FILE_DIMENSION_NOT_VALID:
    'Dimensione file supera il limite di 10mb',
  VALIDATIONS_REQUIRED: 'Campo richiesto',
  VALIDATIONS_REQUIRED_RICHIESTA: 'Inserisci almeno una richiesta',
  VALIDATIONS_REQUIRED_CHECK_SINGOLO: 'Selezione obbligatoria',
  VALIDATIONS_ERROR_YEAR: "L'anno non può essere inferiore all'odierno",
  VALIDATIONS_PATTERN_YEAR: "L'anno deve avere 4 cifre numeriche",
  FORM_SAVE: 'Salva',
  FORM_FILTER: 'Filtra',
  FORM_SEARCH: 'Cerca',
  FORM_RESET: 'Cancella',
  UTILS_CANCEL: 'Annulla',
  UTILS_ERROR: 'Errore',
  UTILS_WARNING: 'Attenzione',
  UTILS_CHIEDE: 'Chiede',
  UTILS_LAST_UPDATE: 'Ultimo aggiornamento',
  UTILS_FACOLTATIVO: '(Facoltativo)',
  UTILS_REMOVE: 'Rimuovi',
  UTILS_CONFIRM: 'Conferma',
  UTILS_ADD: 'Aggiungi',
  UTILS_UPDATE: 'Aggiorna',
  UTILS_FOUND_RESULTS: 'risultati trovati',
  UTILS_TABLE_EMPTY: 'Nessun risultato trovato',
  UTILS_FORM_LABEL_NOT_MANDATORY: 'facoltativo',
  UTILS_TOASTR_ERROR_GENERIC_TITLE: 'Errore',
  SAVE_DRAFT: 'Salva bozza',
  CONFIRM: 'Conferma',
  CLOSE: 'Chiudi',
  GO_BACK: 'Indietro',
  GO_FORWARD: 'Avanti',
  UTILS_YES: 'Si',
  UTILS_NO: 'No',

  UTILS_AUTOCOMPLETE_NOT_FOUND: 'Nessun risultato trovato',
  STEP_GENERICO: 'STEP_GENERICO',
  RIFERIMENTI: 'Riferimenti',
  DATI_RICHIEDENTE: 'Dati richiedente',
  ACCESSO_DATI: 'Accesso dati',
  DATI_DELEGATI: 'Dati delegati',
  FUNZIONE_: '---',
  FUNZIONE_CapoUfficio: 'Capo Ufficio',
  FUNZIONE_Amministratore: 'Amministratore',
  FUNZIONE_RappresentanteLegale: 'Rappresentante Legale',
  DESKTOP_AGENDA_BREADCRUMB_HOME: 'Home',
  DESKTOP_AGENDA_BREADCRUMB_DESKTOP: 'Scrivania',
  DESKTOP_AGENDA_BREADCRUMB_AGENDA: 'Agenda',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_FILTER_BUTTON: 'Filtra operatori',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_COLUMNS_NAME: 'Nome utente',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_COLUMNS_FISCAL_CODE: 'Codice Fiscale',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_FETCH_ERROR_TITLE: 'Errore',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_FETCH_ERROR_TEXT:
    'Non è stato possibile recuperare gli utenti, riprova più tardi',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_FILTER_FORM_NAME_LABEL: 'Nome',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_FILTER_FORM_NAME_PLACEHOLDER:
    'Cerca per nome utente',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_FILTER_FORM_SURNAME_LABEL: 'Cognome',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_FILTER_FORM_SURNAME_PLACEHOLDER:
    'Cerca per cognome utente',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_FILTER_FORM_FISCAL_CODE_LABEL:
    'Codice fiscale',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_FILTER_FORM_FISCAL_CODE_PLACEHOLDER:
    'Cerca per CF utente',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_TITLE: 'Aggiungi operatori',
  DESKTOP_PRATICHE_CREA_COLLABORATORI_TEXT:
    'Seleziona gli altri utenti, tra quelli abilitati, che possono lavorare in qualità di delegato alla pratica.',
  DESKTOP_PRATICHE_FIRMATARIO_TITLE: 'Seleziona firmatario',
  DESKTOP_PRATICHE_FIRMATARIO_TEXT:
    'Seleziona un utente con ruolo firmatario della pratica',
  DESKTOP_PRATICHE_FIRMATARIO_FILTER_BUTTON: 'Filtra firmatari',
  DESKTOP_PRATICHE_FIRMATARIO_USERS_FORM_VALIDATION_LENGTH:
    'Devi selezionare un solo elemento dalla lista',

  QUADRO_BENI_TITLE: 'Gestisci mezzi',
  QUADRO_BENI_SUBTITLE:
    'Sezione contente la lista dei mezzi che verranno impiegati nei lavori, qui è possibile aggiungere, modificare o rimuovere mezzi',
  QUADRO_BENI_DIALOG_TITLE: 'Dati del mezzo selezionato',
  QUADRO_BENI_TABLE_TIPO_VEICOLO: 'Tipo veicolo',
  QUADRO_BENI_TABLE_TARGA: 'Targa',
  QUADRO_BENI_TABLE_MODELLO: 'Modello',
  QUADRO_BENI_TABLE_MARCA: 'Marca',
  QUADRO_BENI_DETAIL_SEARCH_BY_TARGA:
    'Se il mezzo è già presente in anagrafica cercalo tramite targa o numero di telaio per compilare automaticamente i campi',
  QUADRO_BENI_DETAIL_TIPO_VEICOLO: 'Tipo veicolo',
  QUADRO_BENI_DETAIL_SEARCH_TARGA_TELAIO: 'Targa o telaio',
  QUADRO_BENI_DETAIL_SEARCH_TARGA_TELAIO_PLACEHOLDER:
    'Inserisci targa o telaio',
  QUADRO_BENI_DETAIL_TARGA: 'Targa',
  QUADRO_BENI_DETAIL_DATA_IMMATRICOLAZIONE: 'Data immatricolazione',
  QUADRO_BENI_DETAIL_MODELLO: 'Modello',
  QUADRO_BENI_DETAIL_MARCA: 'Marca',
  QUADRO_BENI_DETAIL_TELAIO: 'Telaio',
  QUADRO_BENI_DETAIL_ATTIVITA_CORRELATE: 'Attività correlate',
  QUADRO_BENI_DETAIL_ATTIVITA_CORRELATA: 'Attività correlata',
  QUADRO_BENI_DETAIL_NAZIONE: 'Nazionalità',
  QUADRO_BENI_DETAIL_NAZIONE_PLACEHOLDER:
    "Inserisci nazionalità d'immatricolazione del veicolo",
  QUADRO_BENI_DETAIL_TIPO_VEICOLO_PLACEHOLDER: 'Inserisci tipo veicolo',
  QUADRO_BENI_DETAIL_TARGA_PLACEHOLDER: 'Inserisci targa',
  QUADRO_BENI_DETAIL_DATA_IMMATRICOLAZIONE_PLACEHOLDER:
    'Inserisci data immatricolazione',
  QUADRO_BENI_DETAIL_MODELLO_PLACEHOLDER: 'Inserisci modello',
  QUADRO_BENI_DETAIL_TELAIO_TARGA_ERROR: 'Inserisci uno tra targa o telaio',
  QUADRO_BENI_DETAIL_MARCA_PLACEHOLDER: 'Inserisci marca',
  QUADRO_BENI_DETAIL_TELAIO_PLACEHOLDER: 'Inserisci telaio',
  QUADRO_BENI_DETAIL_ATTIVITA_CORRELATE_PLACEHOLDER:
    'Inserisci attività correlate',
  QUADRO_BENI_CREATE_NEW_BUTTON: 'Aggiungi nuovo mezzo',
  QUADRO_BENI_EDIT_BUTTON: 'Modifica mezzo',
  QUADRO_BENI_DELETE_BUTTON: 'Rimuovi mezzo',
  QUADRO_BENI_DELETE_TITLE: 'Rimuovi mezzo',
  QUADRO_BENI_DELETE_TEXT: 'Confermi di voler rimuovere il mezzo selezionato?',
  QUADRO_BENI_SUCCESS_TITLE: 'Operazione completata',
  QUADRO_BENI_ADD_SUCCESS_TEXT: 'Il mezzo è stato inserito',
  QUADRO_BENI_EDIT_SUCCESS_TEXT: 'Il mezzo è stato modificato',
  QUADRO_BENI_DELETE_SUCCESS_TEXT: 'Il mezzo è stato rimosso',
  QUADRO_BENI_DETAIL_TITLE: 'Dettaglio del mezzo selezionato',
  QUADRO_DETAIL_BENI_TITLE: 'Mezzi',
  QUADRO_DETAIL_BENI_SUBTITLE:
    'Sezione contente la lista dei mezzi che verranno impiegati nei lavori',
  QUADRO_BENI_DETAIL_AUTONOLEGGIO: 'Autonoleggio',
  QUADRO_BENI_DETAIL_NOME_AUTONOLEGGIO: 'Nome autonoleggio',
  QUADRO_BENI_DETAIL_NOME_AUTONOLEGGIO_PLACEHOLDER:
    'Inserisci nome autonoleggio',
  QUADRO_BENI_DETAIL_DATA_INIZIO_AUTONOLEGGIO: 'Data inizio noleggio',
  QUADRO_BENI_DETAIL_DATA_FINE_AUTONOLEGGIO: 'Data fine noleggio',

  DESKTOP_PRATICHE_CREA_DOCUMENTI_TITLE: 'Allegati',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_SUBTITLE:
    "Allegare fotocopia di un documento d'identità in corso di validità. Il documento non va trasmesso se la richiesta è sottoscritta con firma digitale o con altro tipo di firma elettronica qualificata. Il documento d'identità deve essere allegato anche in caso di trasmissione dell’istanza a mezzo posta elettronica e/o posta elettronica certificata",
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_BUTTON_NEW_DOCUMENTO: 'Nuovo documento',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_NEW_DOCUMENT_LABEL_INPUT_FILE:
    'Puoi allegare solo file .pdf, .jpg, .png, .tiff, .p7m (dimensione massima 10mb)',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_NEW_DOCUMENT_BUTTON_INPUT_FILE: 'Scegli',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_NEW_DOCUMENT_LABEL_INPUT_TEXT:
    'Nome file',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_NEW_DOCUMENT_PLACEHOLDER_INPUT_TEXT:
    'Inserisci nome del documento',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_TABLE_DOCUMENTO_NOME: 'Nome documento',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_TABLE_DOCUMENTO_DATA: 'Data caricamento',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_LIST_FETCH_ERROR_TITLE: 'Errore',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_LIST_FETCH_ERROR_TEXT:
    'Non è stato possibile recuperare i documenti, riprova più tardi',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_NEW_DOCUMENT_ERROR_SIZE_MESSAGE:
    'Superata dimensione massima, non superare i 10mb',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_NEW_DOCUMENT_ERROR_EXTENCION_MESSAGE:
    'Estensione del file non valida',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_NEW_DOCUMENT_ERROR_TITLE: 'Errore',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_NEW_DOCUMENT_ERROR_MESSAGE:
    'È stato riscontrato un errore durante il caricamento del file, riprova più tardi',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_NEW_DOCUMENT_SUCCESS_TITLE:
    'Documento salvato',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_NEW_DOCUMENT_SUCCESS_MESSAGE:
    'Il documento è stato correttamente salvato',
  DESKTOP_PRATICHE_CREA_TAG_LIST_TOASTR_ERROR_DELETED_TITLE: 'Errore',
  DESKTOP_PRATICHE_CREA_TAG_LIST_TOASTR_ERROR_DELETED_MESSAGE:
    'Non è stato possibile eliminare il tipo Tag selezionato, riprova più tardi',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_NEW_DOCUMENT_SUCCESS_DELETED_TITLE:
    'Documento eliminato',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_NEW_DOCUMENT_SUCCESS_DELETED_MESSAGE:
    'Il documento selezionato è stato correttamente eliminato',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_NEW_DOCUMENT_ERROR_SCARICARE_MESSAGE:
    'Non è stato possibile scaricare il documento selezionato, riprova più tardi',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_NEW_DOCUMENT_TITLE: 'Nuovo documento',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_NEW_DOCUMENT_TEXT:
    'Seleziona il file da caricare e premi Carica',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_NEW_DOCUMENT_BUTTON_CONFIRM: 'Carica',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_NEW_DOCUMENT_POP_CONFIRM_DELETE_TITLE:
    'Rimuovi documento',
  DESKTOP_PRATICHE_CREA_DOCUMENTI_ADD_NEW_DOCUMENT_POP_CONFIRM_DELETE_TEXT:
    'Confermi di voler rimuovere il documento selezionato?',
  DESKTOP_PRATICHE_CREA_DOCUMENT_BUTTON_DELETE: 'Elimina documento',

  DESKTOP_PRATICHE_CREA_DOCUMENT_SUA_TITLE:
    'Per aprire il procedimento è necessario acquisire il documento della pratica firmato digitalmente',
  DESKTOP_PRATICHE_CREA_DOCUMENT_SUA_TEXT:
    'Scarica il documento della pratica, quindi firmalo digitalmente e ricaricalo tramite il campo sotto.',
  DESKTOP_PRATICHE_CREA_DOCUMENT_SUA_BUTTON_DOWNLOAD_PRATICA: 'Scarica pratica',
  DESKTOP_PRATICHE_CREA_DOCUMENT_SUA_LABEL_FILE:
    'Seleziona il file firmato, inserisci il nome e premi Carica',
  DESKTOP_PRATICHE_CREA_DOCUMENT_SUA_BUTTON_FILE: 'Scegli file',
  DESKTOP_PRATICHE_CREA_DOCUMENT_SUA_LABEL_TEXT: 'Nome file',
  DESKTOP_PRATICHE_CREA_DOCUMENT_SUA_PLACEHOLDER_TEXT:
    'Inserisci il nome del documento firmato digitalmente',
  DESKTOP_PRATICHE_CREA_DOCUMENT_SUA_BUTTON_FILE_DOWNLOAD: 'Carica pratica',
  DESKTOP_PRATICHE_DATI_ISTANZA_LABEL_ISTANZA: 'Numero istanza',
  DESKTOP_PRATICHE_DATI_ISTANZA_LABEL_DATA: 'Data protocollo istanza',
  DESKTOP_PRATICHE_DATI_ISTANZA_LABEL_AMBITO: 'Ambito',
  DESKTOP_PRATICHE_DATI_ISTANZA_LABEL_OGGETTO: 'Oggetto',

  DESKTOP_PRATICHE_UFFICIO_DETENTORE_DATO_TITLE: 'Ufficio Detentore del Dato',
  DESKTOP_PRATICHE_UFFICIO_DETENTORE_DATO_ADD_NEW_ESITO_LABEL_INPUT_TEXT:
    'Esito',
  DESKTOP_PRATICHE_UFFICIO_DETENTORE_DATO_ADD_NEW_ESITO_PLACEHOLDER_INPUT_TEXT:
    "Seleziona l'esito",
  DESKTOP_PRATICHE_UFFICIO_DETENTORE_DATO_ADD_NEW_DATA_ESITO_LABEL_INPUT_TEXT:
    'Data esito',
  DESKTOP_PRATICHE_UFFICIO_DETENTORE_DATO_ADD_NEW_RICORSO_LABEL_INPUT_TEXT:
    'Ricorso al Giudice Amministrativo',
  DESKTOP_PRATICHE_UFFICIO_DETENTORE_DATO_ADD_NEW_RICORSO_PLACEHOLDER_INPUT_TEXT:
    'Seleziona scelta del ricorso',

  DESKTOP_PRIVACY_TITLE:
    'INFORMATIVA ai sensi del Regolamento 2016/679/UE – GDPR',
  DESKTOP_PRIVACY_CONSENSO: 'Presto il consenso',
  DESKTOP_PRATICHE_CREA_PRIVACY_FETCH_ERROR_TITLE: 'Errore',
  DESKTOP_PRATICHE_CREA_PRIVACY_FETCH_ERROR_TEXT:
    "Non è stato possibile recuperare l'informativa sulla privacy, riprova più tardi",

  DESKTOP_PRATICHE_CREA_RIFERIMENTI_TITLE: 'Riferimenti',
  DESKTOP_PRATICHE_CREA_RIFERIMENTI_ADSP: 'ADSP',
  DESKTOP_PRATICHE_CREA_RIFERIMENTI_UFFICIO_UNICO: 'Ufficio unico',
  DESKTOP_PRATICHE_CREA_RIFERIMENTI_UFFICIO_COMPETENTE: 'Ufficio competente',
  DESKTOP_PRATICHE_CREA_RIFERIMENTI_UFFICIO_COMPETENTE_PLACEHOLDER:
    "Seleziona l'ufficio competente",
  DESKTOP_PRATICHE_CREA_RICHIEDENTE_TITLE: 'Dati richiedente',
  DESKTOP_PRATICHE_CREA_X_CONTO_TITLE: 'Per conto di',
  DESKTOP_PRATICHE_CREA_X_CONTO_DEN_AZIE: 'Denominazione azienda',
  DESKTOP_PRATICHE_CREA_X_CONTO_COD_IDEN: 'Codice identificativo',
  DESKTOP_PRATICHE_CREA_X_CONTO_COD_FIS: 'Codice fiscale',
  DESKTOP_PRATICHE_CREA_X_CONTO_P_IVA: 'Partita Iva',
  DESKTOP_PRATICHE_CREA_X_CONTO_INDIRIZZO_LEG: 'Indirizzo sede legale',
  DESKTOP_PRATICHE_CREA_X_CONTO_CAP_LEG: 'CAP',
  DESKTOP_PRATICHE_CREA_X_CONTO_NAZIONE_LEG: 'Nazione sede legale',
  DESKTOP_PRATICHE_CREA_X_CONTO_PROV_LEG: 'Provincia sede legale',
  DESKTOP_PRATICHE_CREA_X_CONTO_COM_LEG: 'Comune sede legale',
  DESKTOP_PRATICHE_CREA_X_CONTO_EMAIL: 'Email',
  DESKTOP_PRATICHE_CREA_X_CONTO_PEC: 'PEC',
  DESKTOP_PRATICHE_CREA_X_CONTO_TEL: 'Telefono',
  DESKTOP_PRATICHE_CREA_X_CONTO_I_CC: 'Iscrizione alla Camera di Commercio',
  DESKTOP_PRATICHE_CREA_X_CONTO_REA: 'Numero REA',
  DESKTOP_PRATICHE_CREA_X_CONTO_INDIRIZZO_FISC: 'Indirizzo sede fiscale',
  DESKTOP_PRATICHE_CREA_X_CONTO_CAP_FISC: 'CAP sede fiscale',
  DESKTOP_PRATICHE_CREA_X_CONTO_NAZIONE_FISC: 'Nazione sede fiscale',
  DESKTOP_PRATICHE_CREA_X_CONTO_PROV_FISC: 'Provincia sede fiscale',
  DESKTOP_PRATICHE_CREA_X_CONTO_COM_FISC: 'Comune sede fiscale',
  DESKTOP_PRATICHE_CREA_X_CONTO_TIPO_AZIENDA: 'Tipo azienda',
  DESKTOP_PRATICHE_CREA_X_CONTO_TIPO_SOCIETA: 'Tipo società',
  DESKTOP_PRATICHE_CREA_X_CONTO_PROVINCE_REA: 'Provincia REA',
  DESKTOP_PRATICHE_CREA_X_CONTO_CAPITALE_SOCIALE: 'Capitale sociale',
  DESKTOP_PRATICHE_CREA_X_CONTO_SCADENZA_SOCIETA: 'Data scadenza società',
  DESKTOP_PRATICHE_CREA_X_CONTO_DURATA_SOCIETA: 'Durata società',
  DESKTOP_PRATICHE_CREA_X_CONTO_NUMERO_ISCRIZIONE_AA:
    'Numero iscrizione Albo Autotrasporti',
  DESKTOP_PRATICHE_CREA_X_CONTO_NUMERO_ISCRIZIONE_REN: 'Numero iscrizione REN',
  DESKTOP_PRATICHE_CREA_ACCESSO_LABEL_RADIO_TITLE: 'Considerata',
  DESKTOP_PRATICHE_CREA_ACCESSO_LABEL_RADIO_OMESSA_PUB:
    'l’omessa pubblicazione',
  DESKTOP_PRATICHE_CREA_ACCESSO_LABEL_RADIO_PART_PUB:
    'la pubblicazione parziale',
  DESKTOP_PRATICHE_CREA_ACCESSO_TEXT:
    'del seguente documento/informazione/dato che in base alla normativa vigente non risulta pubblicato nella sezione “Amministrazione Trasparente” sulla sezione del sito www.portsofcatania.com di seguito indicata:',
  DESKTOP_PRATICHE_CREA_ACCESSO_LABEL_SEZ_AMM:
    'Sezione ambito/sezione Amministrazione trasparente',
  DESKTOP_PRATICHE_CREA_ACCESSO_PLACEHOLDER_SEZ_AMM:
    'Seleziona sezione Amministrazione trasparente',
  DESKTOP_PRATICHE_CREA_ACCESSO_PLACEHOLDER_AMBITO:
    'Seleziona ambito/sezione Amministrazione trasparente',
  DESKTOP_PRATICHE_CREA_ACCESSO_LABEL_RIFER: 'Riferimenti doc./norma',
  DESKTOP_PRATICHE_CREA_ACCESSO_PLACEHOLDER_RIFER: 'Descrivi il documento',
  DESKTOP_PRATICHE_CREA_ACCESSO_PLACEHOLDER_ALTRO_AMIBTO_LABEL: 'Altro ambito',
  DESKTOP_PRATICHE_CREA_ACCESSO_PLACEHOLDER_ALTRO_AMIBTO_PLACEHOLDER:
    'Inserisci altro ambito',
  DESKTOP_PRATICHE_CREA_ACCESSO_PLACEHOLDER_OGG:
    "Descrivi l'oggetto della richiesta",
  DESKTOP_PRATICHE_CREA_ACCESSO_LABEL_OGG: "Descrivi l'oggetto della richiesta",
  DESKTOP_PRATICHE_CREA_ACCESSO_LABEL_AMBITI:
    'Seleziona ambito/sez. Amm. trasparente',
  DESKTOP_PRATICHE_CREA_ACCESSO_PLACEHOLDER_AMBITI: 'Seleziona ambito',
  DESKTOP_PRATICHE_CREA_ACCESSO_BUTTON_ADD_REQUEST: 'Aggiungi richiesta',
  DESKTOP_PRATICHE_CREA_ACCESSO_BUTTON_ADD_REQUEST_TITLE:
    'Il bottone si attiva dopo aver compilato i dati di pubblicazione, sezione Amm. e riferimento',
  DESKTOP_PRATICHE_CREA_ACCESSO_TITLE_TABLE: 'Richieste di accesso',
  DESKTOP_PRATICHE_CREA_ACCESSO_TEXT_NORMATIVA:
    'ai sensi e per gli effetti dell’art. 5 del d.lgs n. 33 del 14 marzo 2013, la pubblicazione di quanto richiesto e la comunicazione alla/ al medesima/o dell’avvenuta pubblicazione, indicando il collegamento ipertestuale al dato/informazione oggetto dell’istanza. Nel caso di accoglimento della richiesta la comunicazione contenente il collegamento ipertestuale sarà disponibile:',
  DESKTOP_PRATICHE_CREA_ACCESSO_TEXT_NORMATIVA_1:
    '- sul portale SUA nella sezione “Le mie pratiche”.',
  DESKTOP_PRATICHE_CREA_ACCESSO_TEXT_NORMATIVA_2:
    '- alla casella di posta elettronica indicata dall’utente sul proprio profilo del portale (vedi sezione “Profilo Utente”).',
  DESKTOP_PRATICHE_CREA_ACCESSO_TEXT_NORMATIVA_3:
    '- nel caso si agisca in nome e/o per conto della persona giuridica la comunicazione sarà altresì inoltrata alla PEC aziendale.',
  DESKTOP_PRATICHE_CREA_ACCESSO_MODALE_TITLE: 'Info',
  DESKTOP_PRATICHE_CREA_ACCESSO_MODALE_TEXT:
    'Indicazione riferimenti del documento/informazione/dato, nel caso sia a conoscenza dell’istante, specificare la norma che impone la pubblicazione di quanto richiesto',
  DESKTOP_PRATICHE_CREA_ACCESSO_ICON_DELETE_TITLE: 'Cancella richiesta',
  DESKTOP_PRATICHE_CREA_ACCESSO_TABLE_COLUMN_SET: 'Sez. Amm. trasparente',
  DESKTOP_PRATICHE_CREA_ACCESSO_TABLE_COLUMN_AMBITO:
    'Ambito/Sez. Amm. trasparente',
  DESKTOP_PRATICHE_CREA_ACCESSO_TABLE_COLUMN_AMBITO_ALTRO: 'Altro ambito',
  DESKTOP_PRATICHE_CREA_ACCESSO_TABLE_COLUMN_RIF: 'Riferimento',
  DESKTOP_PRATICHE_CREA_ACCESSO_TABLE_COLUMN_PUB: 'Pubblicazione',
  DESKTOP_PRATICHE_CREA_ACCESSO_TABLE_COLUMN_PUB_NO_PUBBLICATION: 'Omessa',
  DESKTOP_PRATICHE_CREA_ACCESSO_TABLE_COLUMN_PUB_PARTIAL_PUBBLICATION:
    'Parziale',
  DESKTOP_PRATICHE_CREA_ACCESSO_ICON_INFO_TITLE: 'Info',
  DESKTOP_PRATICHE_CREA_RICHIESTE_ACCESSO_TITLE: 'Richieste di accesso',
  DESKTOP_PRATICHE_DETAIL_ACCESSO_OGGETTO: 'Oggetto',

  SCRIVANIA_ESITO_PAGAMENTO_TITLE: 'Esito del pagamento',
  SCRIVANIA_ESITO_PAGAMENTO_SUCCESS_TITLE: 'Pagamento effettuato con successo',
  SCRIVANIA_ESITO_PAGAMENTO_ERROR_TITLE: 'Pagamento non effettuato',
  SCRIVANIA_ESITO_PAGAMENTO_SUCCESS_MESSAGE:
    'Non ci sono stati problemi durante il processo di pagamento.',
  SCRIVANIA_ESITO_PAGAMENTO_ERROR_MESSAGE:
    'Qualcosa è andato storto durante il processo di pagamento',
  SCRIVANIA_ESITO_PAGAMENTO_TEXT_LIST:
    'In ogni momento puoi seguire lo stato dei pagamenti a te assegnati accedendo alla sezione',
  SCRIVANIA_ESITO_PAGAMENTO_BUTTON_LIST: '-Lista Pagamenti',
  SCRIVANIA_ESITO_PAGAMENTO_BUTTON_TO_PRATICA: 'Torna alla pratica',

  QUADRO_VERIFICA_SOTTOSCRIZIONE_TITLE: 'Validità istanza',
  QUADRO_VERIFICA_SOTTOSCRIZIONE_TEXT:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata',
  QUADRO_VERIFICA_SOTTOSCRIZIONE_LABEL: 'L’istanza presentata è',
  QUADRO_VERIFICA_SOTTOSCRIZIONE_PLACEHOLDER: "Seleziona validità dell'istanza",

  QUADRO_VERIFICA_PROCEDIBILITA_TITLE: 'Procedibilità istanza',
  QUADRO_VERIFICA_PROCEDIBILITA_TEXT:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata',
  QUADRO_VERIFICA_PROCEDIBILITA_LABEL: 'L’istanza presentata è',
  QUADRO_VERIFICA_PROCEDIBILITA_PLACEHOLDER:
    "Seleziona procedibilità dell'istanza",

  QUADRO_PRATICA_ESITO_DESCRIZIONE_1:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata',
  QUADRO_PRATICA_ESITO_TITLE_1: 'Pratica salvata in bozza',
  QUADRO_PRATICA_ESITO_MESSAGE_1:
    'Le informazioni inserite nella pratica sono state salvate',
  QUADRO_PRATICA_ESITO_DESCRIZIONE_0:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata',
  QUADRO_PRATICA_ESITO_TITLE_0: 'Pratica aggiornata',
  QUADRO_PRATICA_ESITO_MESSAGE_0: 'Lo stato della pratica è stato aggiornato.',
  QUADRO_PRATICA_ESITO_TEXT_LIST:
    'In ogni momento puoi seguire lo stato delle pratiche a te assegnate accedendo alla sezione ',
  QUADRO_PRATICA_ESITO_BUTTON_LIST: 'Lista Pratiche',
  QUADRO_PRATICA_ESITO_BUTTON_TO_PRATICA: 'Torna alla pratica',

  TIPO_CONTRATTO_DIPENDENTE_INDETERMINATO: 'Dipendente indeterminato',
  TIPO_CONTRATTO_DIPENDENTE_DETERMINATO: 'Dipendente determinato',
  TIPO_CONTRATTO_SOCIO: 'Socio',
  TIPO_CONTRATTO_ALTRO: 'Altro',

  DESKTOP_PRATICHE_FORM_INPUT_NAME_LABEL: 'Nome',
  DESKTOP_PRATICHE_FORM_INPUT_LASTNAME_LABEL: 'Cognome',
  DESKTOP_PRATICHE_FORM_INPUT_BIRTH_DATE: 'Data di nascita',
  DESKTOP_PRATICHE_FORM_INPUT_FISCAL_CODE: 'Codice fiscale',
  DESKTOP_PRATICHE_FORM_INPUT_BIRTH_NAZIONE: 'Nazione di nascita',
  DESKTOP_PRATICHE_FORM_INPUT_BIRTH_PROVINCE: 'Provincia di nascita',
  DESKTOP_PRATICHE_FORM_INPUT_BIRTH_CITY: 'Comune di nascita',
  DESKTOP_PRATICHE_FORM_INPUT_ADDRESS: 'Indirizzo di residenza',
  DESKTOP_PRATICHE_FORM_INPUT_POSTAL_CODE: 'Cap di residenza',
  DESKTOP_PRATICHE_FORM_INPUT_RESIDENTIAL_STATE: 'Nazione di residenza',
  DESKTOP_PRATICHE_FORM_INPUT_RESIDENTIAL_PROVINCE: 'Provincia di residenza',
  DESKTOP_PRATICHE_FORM_INPUT_RESIDENTIAL_CITY: 'Comune di residenza',
  DESKTOP_PRATICHE_FORM_INPUT_EMAIL_LABEL: 'Email',
  DESKTOP_PRATICHE_FORM_INPUT_EMAIL_PLACEHOLDER: 'Inserisci Email',
  DESKTOP_PRATICHE_FORM_INPUT_PEC_LABEL: 'PEC',
  DESKTOP_PRATICHE_FORM_INPUT_PEC_PLACEHOLDER: 'Inserisci PEC',
  VALIDATIONS_PATTERN_EMAIL: 'Email non valida',
  VALIDATIONS_PATTERN_PEC: 'PEC non valida',
  VALIDATIONS_PATTERN_CF: 'CF non valido',
  DESKTOP_PRATICHE_FORM_INPUT_SESSO_LABEL: 'Sesso',
  DESKTOP_PRATICHE_FORM_INPUT_SESSO_PLACEHOLDER: 'Inserisci il sesso',
  DESKTOP_PRATICHE_FORM_INPUT_CELL_LABEL: 'Cellulare',
  DESKTOP_PRATICHE_FORM_INPUT_TEL_LABEL: 'Telefono',

  DESKTOP_PRATICHE_FORM_INPUT_NAME_PLACEHOLDER: 'Inserisci il nome',
  DESKTOP_PRATICHE_FORM_INPUT_LASTNAME_PLACEHOLDER: 'Inserisci il cognome',
  DESKTOP_PRATICHE_FORM_INPUT_BIRTH_DATE_PLACEHOLDER:
    'Inserisci la data di nascita',
  DESKTOP_PRATICHE_FORM_INPUT_FISCAL_CODE_PLACEHOLDER:
    'Inserisci il codice fiscale',
  DESKTOP_PRATICHE_FORM_INPUT_BIRTH_NAZIONE_PLACEHOLDER:
    'Inserisci la nazione di nascita',
  DESKTOP_PRATICHE_FORM_INPUT_BIRTH_PROVINCE_PLACEHOLDER:
    'Inserisci la provincia di nascita',
  DESKTOP_PRATICHE_FORM_INPUT_BIRTH_CITY_PLACEHOLDER:
    'Inserisci il comune di nascita',
  DESKTOP_PRATICHE_FORM_INPUT_ADDRESS_PLACEHOLDER:
    "Inserisci l'indirizzo di residenza",
  DESKTOP_PRATICHE_FORM_INPUT_POSTAL_CODE_PLACEHOLDER:
    'Inserisci il cap di residenza',
  DESKTOP_PRATICHE_FORM_INPUT_RESIDENTIAL_STATE_PLACEHOLDER:
    'Inserisci la nazione di residenza',
  DESKTOP_PRATICHE_FORM_INPUT_RESIDENTIAL_PROVINCE_PLACEHOLDER:
    'Inserisci la provincia di residenza',
  DESKTOP_PRATICHE_FORM_INPUT_RESIDENTIAL_CITY_PLACEHOLDER:
    'Inserisci il comune di residenza',
  DESKTOP_PRATICHE_FORM_INPUT_SOC_ASSICURATIVA_LABEL: 'Società assicurativa',
  DESKTOP_PRATICHE_FORM_INPUT_SOC_ASSICURATIVA_PLACEHOLDER:
    'Inserisci il nome della società assicurativa',
  DESKTOP_PRATICHE_FORM_INPUT_NUM_POLIZZA_LABEL: 'Numero polizza',
  DESKTOP_PRATICHE_FORM_INPUT_NUM_POLIZZA_PLACEHOLDER:
    'Inserisci il numero della polizza assicurativa',
  DESKTOP_PRATICHE_DATI_ASSICURAZIONE_TITLE: "Dati dell'assicurazione",
  DESKTOP_BPM_UPLOAD_ASSICURAZIONE_TITLE: 'Contratto assicurativo',
  DESKTOP_BPM_UPLOAD_ASSICURAZIONE_LONG_TEXT:
    'Inserisci se presente il contratto assicurativo',
  DESKTOP_BPM_UPLOAD_APERTURA_PRATICA_TITLE: 'Inoltra pratica',
  DESKTOP_BPM_UPLOAD_APERTURA_PRATICA_LONG_TEXT:
    "Confermando questo step la pratica sarà inoltrata all'amministrazione",
  DESKTOP_BPM_UPLOAD_APERTURA_PRATICA_BUTTON_DOWNLOAD_DESCRIPTION:
    'Scarica il documento, quindi apponi una firma digitale o cartacea e caricalo',

  DESKTOP_BPM_UPLOAD_APERTURA_PRATICA_DOCUMENT_LABEL: 'Documento caricato:',
  DESKTOP_BPM_UPLOAD_CIDENTITA_TITLE: 'Documento di riconoscimento',
  DESKTOP_BPM_UPLOAD_CIDENTITA_LONG_TEXT:
    'Inserisci un documento di riconoscimento',
  DESKTOP_BPM_UPLOAD_RINUNCIA_TITLE: 'Documento di rinuncia',
  DESKTOP_BPM_UPLOAD_RINUNCIA_LONG_TEXT: 'Inserisci documento di rinuncia',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_TIPO_DELEGA_FIRMA: 'Firma',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_TIPO_DELEGA_OPERATIVA: 'Operativa',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_TITLE: 'Delegati associati alla pratica',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_SUBTITLE:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_OPERATORI: 'Operatori',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_FIRMATARI: 'Firmatari',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_NAME: 'Nome Delegato',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_VIEW_DETAIL: 'Visualizza',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_VERIFICA: 'Verifica',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_TIPO_DELEGA: 'Tipo delega',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_FISCAL_CODE: 'Codice fiscale',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_START_VALIDITY: 'Inizio validità',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_STOP_VALIDITY: 'Fine validità',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_STATO: 'Stato',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_STATO_: '---',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_STATO_null: '---',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_STATO_BLOCKED: 'Bloccato',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_STATO_UNBLOCKED: 'Sbloccato',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_STATO_INSERITA: 'Inserita',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_STATO_VERIFICATA: 'Verificata',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_STATO_BOZZA: 'Bozza',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_STATO_RIGETTATA: 'Rigettata',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_STATO_REVOCATA: 'Revocata',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_STATO_RIGETTATA_PER_ALTRO_PROCEDIMENTO:
    'Rigettata per altro procedimento',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_DOCUMENTI_ALLEGATI_DELEGA:
    'Documenti allegati alla delega',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_VERIFICA_DELEGA: 'Verifica delega',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_STATO_DELEGA: 'Stato delega',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_NAME_RICHIEDENTE: 'Nome Richiedente',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_VERIFICA_DELEGA_TESTO:
    "Modifica lo stato del procedimento ed eventualmente le date d'inizio e fine validità",
  DESKTOP_BPM_DELEGATI_ASSOCIATI_STATO_DELEGA_SELECT:
    'Stato delega procedimento',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_STATO_DELEGA_SELECT_PLACEHOLDER:
    'Stato delega',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_MOTIVAZIONE: 'Motivazione',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_MOTIVAZIONE_PLACEHOLDER:
    'Inserisci motivazione',
  ACTIONS_FOOTER_SAVE: 'Salva',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_NOME: 'Nome',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_COGNOME: 'Cognome',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_SESSO: 'Sesso',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_DATA_NASCITA: 'Data di nascita',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_COMUNE_NASCITA: 'Comune di nascita',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_PROVINCIA_NASCITA:
    'Provincia di nascita',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_NAZIONE_NASCITA: 'Nazione di nascita',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_INDIRIZZO_RESIDENZA:
    'Indirizzo di residenza',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_CAP_RESIDENZA: 'Cap di residenza',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_COMUNE_RESIDENZA:
    'Comune di residenza',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_PROVINCIA_RESIDENZA:
    'Provincia di residenza',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_NAZIONE_RESIDENZA:
    'Nazione di residenza',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_TELEFONO: 'Telefono',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_CELLULARE: 'Cellulare',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_COLUMNS_EMAIL: 'Email',
  DESKTOP_BPM_DELEGATI_ASSOCIATI_DATI_DELEGATO: 'Dati delegato',

  QUADRO_PRATICHE_TABELLA_MODALE_TITLE: 'Dettaglio Comunicazione',

  ACTIONS_FOOTER_SAVE_EDIT: 'Salva modifiche',

  QUADRO_PRATICHE_PUBBLICAZIONE_ADD_BUTTON: 'Prepara comunicazione',

  QUADRO_PRATICHE_ADD_BUTTON: 'Invia risposta',
  QUADRO_PRATICHE_CLOSE_BUTTON: 'Annulla risposta',

  ACTIONS_BPM_SCARICA_DOC: 'Scarica doc',
  ACTIONS_BPM_CARICA_DOC: 'Carica doc',
  DESKTOP_BPM_SG_TITLE: 'Comunicazioni ricevute dalla Segreteria Generale',
  DESKTOP_BPM_UFF_RICH_COMUNICAZIONE_OGGETTO: 'Oggetto comunicazione',
  DESKTOP_BPM_UFF_RICH_COMUNICAZIONE_DATE: 'Data invio',
  DESKTOP_BPM_UFF_RICH_COMUNICAZIONE_NAME_DOCUMENT: 'Nome file comunicazione',

  DESKTOP_BPM_RESP_DATO_TITLE: 'Responsabilità del dato',
  DESKTOP_BPM_RESP_DATO_SUBTITLE:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum',
  DESKTOP_BPM_RESP_DATO_TBL_TITLE:
    'Seleziona i documenti tra quelli richiesti di propria competenza',
  DESKTOP_BPM_RESP_DATO_COLUMNS_NAME: 'Sezione Amministrazione trasp.',
  DESKTOP_BPM_RESP_DATO_COLUMNS_DESCRIPTION: 'Riferimenti doc./norma',
  DESKTOP_BPM_RESP_DATO_SEL_TITLE:
    'indica il livello di responsabilità sui dati',
  DESKTOP_BPM_RESP_DATO_SEL_PLACEHOLDER: 'Seleziona il tipo di richiesta',

  ACTIONS_BPM_SCEGLI_FILE: 'Scegli file',
  DESKTOP_BPM_COMUNICAZIONE_TITLE_NEW: 'Crea Comunicazione',
  DESKTOP_BPM_COMUNICAZIONE_SUBTITLE:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata',
  COM_AVVIO_PROC_TITLE: 'Crea Comunicazione',
  COM_AVVIO_PROC_LONG_TEXT:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata',

  DESKTOP_BPM_COMUNICAZIONE_TITLE: 'Comunicazione',
  DESKTOP_BPM_COMUNICAZIONE_TITLE_SELECT_DEST_ENTE: 'Destinatario Ente Esterno',
  DESKTOP_BPM_COMUNICAZIONE_TITLE_SELECT_DEST_PLACEHOLDER:
    'Seleziona il Destinatario',

  DESKTOP_BPM_COMUNICAZIONE_TITLE_SELECT_DOCUMENTI_COLUMN_AMM:
    'Sezione Amministrazione trasp.',
  DESKTOP_BPM_COMUNICAZIONE_TITLE_SELECT_DOCUMENTI_COLUMN_RIF:
    'Riferimenti doc./norma',
  DESKTOP_BPM_COMUNICAZIONE_TITLE_SELECT_DOCUMENTI_COLUMN_ESITO: 'Esito',
  ACTIONS_BPM_AGGIUNGI_COMUNICAZIONE_ENTE: "Invia comunicazione all'ente",
  DESKTOP_BPM_COMUNICAZIONE_DESTINATARIO_TITLE: 'Destinatario comunicazione',
  DESKTOP_BPM_COMUNICAZIONE_OGGETTO: 'Oggetto',
  DESKTOP_BPM_COMUNICAZIONE_OGGETTO_PLACEHOLDER:
    'Inserisci breve descrizione oggetto istanza',
  DESKTOP_BPM_COMUNICAZIONE_TEXT:
    'Scarica il documento di riferimento per la comunicazione, quindi compilalo in tutte le sue parti.',
  DESKTOP_BPM_COMUNICAZIONE_NOME_FILE: 'Nome file',
  DESKTOP_BPM_COMUNICAZIONE_NOME_FILE_PLACEHOLDER: 'Nome file',
  DESKTOP_BPM_COMUNICAZIONE_TEXT_FILE:
    'Seleziona il file da caricare e inserisci il nome',
  DESKTOP_BPM_COMUNICAZIONE_CLASS_LABEL: 'Classificazione/Fascicolazione',
  AVVIO_PROC_CLASS_TITOLO_SELECT: 'Classificazione/Fascicolazione',

  COM_FINALE_CLASS_TITOLO_SELECT: 'Classificazione/fascicolazione',
  DESKTOP_BPM_COMUNICAZIONE_UFFICI_TITLE: 'Uffici per conoscenza',
  DESKTOP_BPM_COMUNICAZIONE_UFFICI_TEXT: 'Seleziona ufficio',
  DESKTOP_BPM_COMUNICAZIONE_UFFICI_TABLE_COLUMN_DENOMINAZIONE: 'Denominazione',
  DESKTOP_BPM_COMUNICAZIONE_UFFICI_TABLE_COLUMN_COD_UFFICIO: 'Cod. ufficio',

  ACTIONS_BPM_AGGIUNGI_UFFICI: 'Aggiungi uffici',

  DESKTOP_BPM_TASK_NAME_BUTTON_AVVIO_PROCEDIMENTO: 'Avvio procedimento',
  DESKTOP_BPM_TASK_NAME_BUTTON_APPROVA_RINUNCIA: 'Approvazione rinuncia',
  DESKTOP_BPM_TASK_NAME_BUTTON_COMUNICAZIONE_NON_PROC:
    'Comunicazione non procedibilità istanza',
  DESKTOP_BPM_TASK_NAME_BUTTON_COMUNICAZIONE_NON_VALIDITA:
    'Comunicazione non validità preliminare istanza',
  DESKTOP_BPM_TASK_NAME_BUTTON_COMUNICAZIONE_FINALE: 'Comunicazione finale',
  DESKTOP_BPM_STEP_NAME_COMUNICAZIONE_FINALE: 'Comunicazione',
  DESKTOP_BPM_TASK_NAME_BUTTON_VERIFICA_PROCEDIBILITA:
    'Verifica procedibilità istanza',
  DESKTOP_BPM_TASK_NAME_BUTTON_VERIFICA: 'Verifica preliminare',
  DESKTOP_BPM_TASK_NAME_BUTTON_APERTURA: 'Apertura pratica',
  DESKTOP_BPM_TASK_NAME_BUTTON_RINUNCIA: 'Richiesta di rinuncia',

  DESKTOP_BPM_TASK_NAME_BUTTON_ASSEGNA_RESP_PROC:
    'Assegnazione del Responsabile del Procedimento',
  DESKTOP_BPM_STEP_NAME_ASSEGNA_RESPONSABILE: 'Assegna Ruolo',

  DESKTOP_BPM_TASK_NAME_BUTTON_ASSEGNA_RESP_ISTR:
    "Assegnazione del Responsabile dell'istruttoria",

  DESKTOP_BPM_STEP_NAME_DATI_PRATICA: 'Dati pratica',
  DESKTOP_BPM_STEP_NAME_COLLABORATORI: 'Operatori',
  DESKTOP_BPM_STEP_NAME_DOCUMENTI: 'Documenti',
  DESKTOP_BPM_STEP_NAME_PRIVACY: 'Privacy',
  DESKTOP_BPM_STEP_NAME_VERIFICA_SOTTOSCRIZIONE: 'Validità istanza',
  DESKTOP_BPM_STEP_NAME_RINUNCIA: 'Rinuncia',
  DESKTOP_BPM_STEP_NAME_CONSEGNA_BREVI_MANU: 'Consegna brevi Manu',

  DESKTOP_BPM_STEP_URL_ACCETTAZIONE_RESPONSABILITA: 'responsabilita',
  DESKTOP_BPM_STEP_URL_ASSEGNA_RESPONSABILE: 'assegna-ruolo',
  DESKTOP_BPM_STEP_URL_DATI_PRATICA: 'dati-pratica',
  DESKTOP_BPM_STEP_URL_VERIFICA_SOTTOSCRIZIONE: 'validita-istanza',
  DESKTOP_BPM_STEP_URL_CONSEGNA_BREVI_MANU: 'brevi-manu',
  DESKTOP_BPM_STEP_URL_RINUNCIA: 'rinuncia',
  DESKTOP_BPM_STEP_URL_COMUNICAZIONE_RICHIESTA_INTEGRAZIONE:
    'richiesta-integrazione',
  DESKTOP_BPM_STEP_URL_DATI_DELEGATI: 'dati-delegati',
  DESKTOP_BPM_STEP_URL_VERIFICA_PROCEDIBILITA: 'verifica',
  DESKTOP_BPM_STEP_URL_COMUNICAZIONE_NON_VALIDITA: 'comunicazione',
  DESKTOP_BPM_STEP_URL_COMUNICAZIONE_NON_PROC: 'comunicazione',
  DESKTOP_BPM_STEP_URL_PRIVACY: 'privacy',
  DESKTOP_BPM_STEP_URL_DOCUMENTI: 'documenti',
  DESKTOP_BPM_STEP_URL_COLLABORATORI: 'operatori',
  DESKTOP_BPM_STEP_NAME_OPERATORI: 'Operatori',
  DESKTOP_BPM_STEP_URL_VALIDITA_ISTANZA: 'validita-istanza',
  DESKTOP_BPM_STEP_URL_UPLOAD_ALLEGATI: 'upload-allegati',
  DESKTOP_BPM_STEP_URL_COMUNICAZIONE_PUBBLICAZIONE: 'pubblicazione',
  DESKTOP_BPM_STEP_URL_CHIUSURA_REGISTRO_ACCESSI: 'chiusura',
  DESKTOP_BPM_STEP_URL_APERTURA_REGISTRO_ACCESSI: 'apertura',
  DESKTOP_BPM_STEP_URL_AVVIO_PROCEDIMENTO: 'comunicazione',
  DESKTOP_BPM_STEP_URL_COMUNICAZIONE_FO: 'comunicazioni',
  DESKTOP_BPM_STEP_URL_COMUNICAZIONE_BO: 'comunicazioni',
  DESKTOP_BPM_STEP_URL_COMUNICAZIONI_SG: 'comunicazioni',
  DESKTOP_BPM_STEP_URL_COMUNICAZIONE_FINALE: 'finale',
  DESKTOP_BPM_STEP_URL_OPERATORI: 'operatori',

  DESKTOP_BPM_STEP_URL_VERIFICA_INTEGRAZIONI_PREVVISO: 'preavviso',
  DESKTOP_BPM_STEP_URL_ACQUISIZIONE_PARERE_ENTI_ESTERNI: 'parere-esterni',
  DESKTOP_BPM_STEP_URL_COMUNICAZIONE_DINIEGO: 'diniego',
  DESKTOP_BPM_STEP_URL_COMUNICAZIONE_AUTORIZZAZIONE: 'autorizzazione',
  DESKTOP_BPM_STEP_URL_INSERIMENTO_DOC_INTEGRAZIONI: 'integrazione',
  DESKTOP_BPM_STEP_URL_COMUNICAZIONE_PREAV_DINIEGO: 'preavviso-diniego',
  DESKTOP_BPM_STEP_URL_INOLTRO: 'inoltra-pratica',
  DESKTOP_BPM_STEP_URL_PRATICA_ASSOCIATA: 'associa-pratica',
  DESKTOP_BPM_STEP_URL_ISTANZA: 'seleziona-istanza',
  DESKTOP_BPM_STEP_URL_VERIFICA_INTEGRAZIONI: 'validita',
  DESKTOP_BPM_STEP_URL_SPECIFICHE_NUOVE_OPERE: 'seleziona-porto',
  DESKTOP_BPM_STEP_URL_DATI_PORTO_SERVIZI: 'dati-porto',
  DESKTOP_BPM_STEP_URL_AUTODICHIARAZIONE: 'autodichiarazioni',
  DESKTOP_BPM_STEP_URL_PERSONALE: 'dipendenti',
  DESKTOP_BPM_STEP_URL_BENI: 'beni',
  DESKTOP_BPM_STEP_URL_VERIFICA_PAGAMENTO: 'pagamento',
  DESKTOP_BPM_STEP_URL_COMUNICAZIONE_PARERE_ENTI_ESTERNI: 'parere',

  DESKTOP_BPM_STEP_URL_PAGAMENTO: 'effettu-pagamento',
  DESKTOP_BPM_STEP_NAME_AVVIO_PROCEDIMENTO: 'Comunicazione avvio procedimento',
  DESKTOP_BPM_STEP_NAME_COMUNICAZIONI_SG: 'Comunicazione responsabilità SG',
  DESKTOP_BPM_STEP_NAME_COMUNICAZIONE_NON_VALIDITA:
    'Comunicazione non validità',
  DESKTOP_BPM_STEP_NAME_VERIFICA_PROCEDIBILITA: 'Verifica procedibilità',
  DESKTOP_BPM_STEP_NAME_DATI_DELEGATI: 'Dati delegati',
  DESKTOP_BPM_STEP_NAME_COMUNICAZIONE_NON_PROC:
    'Comunicazione non procedibilità',
  DESKTOP_BPM_STEP_NAME_UPLOAD_ALLEGATI: 'Upload Allegati',
  DESKTOP_BPM_STEP_NAME_APERTURA_REGISTRO_ACCESSI: 'Apertura Registro accessi',
  DESKTOP_BPM_TASK_NAME_BUTTON_APERTURA_REG_ACC:
    'Apertura procedimento su Registro accessi',
  DESKTOP_BPM_STEP_NAME_CHIUSURA_REGISTRO_ACCESSI: 'Chiusura Registro accessi',
  DESKTOP_BPM_TASK_NAME_BUTTON_CHIUSURA_REG_ACC:
    'Chiusura procedimento su Registro accessi',
  DESKTOP_BPM_STEP_NAME_COMUNICAZIONE_PUBBLICAZIONE:
    'Comunicazione pubblicazione',
  DESKTOP_BPM_STEP_NAME_ACQUISIZIONE_PARERE_ENTI_ESTERNI: 'Enti Esterni',
  DESKTOP_BPM_TASK_NAME_BUTTON_ACQUISIZIONE_PARERE_ENTI_ESTERNI:
    'Acquisizione pareri Enti Esterni',
  DESKTOP_BPM_STEP_NAME_SPECIFICHE_NUOVE_OPERE: 'Seleziona il porto',
  DESKTOP_BPM_TASK_NAME_BUTTON_COMUNICA_PUBBL:
    'Comunicazione avvenuta pubblicazione',
  DESKTOP_BPM_TASK_NAME_BUTTON_COMUNICA_SG:
    'Comunicazione responsabilità del dato a SG',
  DESKTOP_BPM_STEP_NAME_VALIDITA_ISTANZA: 'Validità istanza',
  DESKTOP_BPM_STEP_NAME_PRATICA_ASSOCIATA: 'Associa pratica',
  DESKTOP_BPM_STEP_NAME_ISTANZA: 'Istanza',
  DESKTOP_BPM_STEP_NAME_ACCETTAZIONE_RESPONSABILITA: 'Accettazione incarico',
  DESKTOP_BPM_TASK_NAME_BUTTON_ACCETTAZIONE_RESP: 'Accettazione responsabilità',
  DESKTOP_BPM_STEP_NAME_VERIFICA_INTEGRAZIONI: 'Verifica integrazioni',

  DESKTOP_BPM_STEP_NAME_VERIFICA_INTEGRAZIONI_PREVVISO: 'Verifica pravviso',
  DESKTOP_BPM_TASK_NAME_BUTTON_VERIFICA_PREAVVISO: 'Verifica preavviso',
  DESKTOP_BPM_STEP_NAME_VERIFICA_INTEGRAZION: 'Verifica integrazione',
  DESKTOP_BPM_TASK_NAME_BUTTON_VERIFICA_INTEGRAZIONI: 'Verifica integrazione',
  DESKTOP_BPM_STEP_NAME_DATI_PORTO_SERVIZI: 'Dati porto',
  DESKTOP_BPM_STEP_NAME_AUTODICHIARAZIONE: 'Autodichiarazioni',
  DESKTOP_BPM_STEP_NAME_BENI: 'Mezzi',
  DESKTOP_BPM_STEP_NAME_PERSONALE: 'Dipendenti',
  DESKTOP_BPM_STEP_NAME_PAGAMENTO: 'Pagamento',
  DESKTOP_BPM_STEP_NAME_VERIFICA_PAGAMENTO: 'Verifica',
  DESKTOP_BPM_TASK_NAME_BUTTON_VERIFICA_PAGAMENTO: 'Verifica pagamento',
  DESKTOP_BPM_QUADRO_RIFERIMENTI: 'Riferimenti',

  DESKTOP_BPM_SAVE_STEP_ERROR_TITLE: 'Errore',
  DESKTOP_BPM_SAVE_STEP_ERROR_MESSAGE:
    'Non è stato possibile eseguire il salvataggio dello step corrente, riprova più tardi',
  DESKTOP_BPM_SAVE_TASK_ERROR_TITLE: 'Errore',
  DESKTOP_BPM_SAVE_TASK_ERROR_MESSAGE:
    'Non è stato possibile eseguire il salvataggio del task appena completato, riprova più tardi',

  DESKTOP_BPM_QUADRI_FETCH_ERROR_TITLE: 'Errore',
  DESKTOP_BPM_QUADRI_FETCH_ERROR_MESSAGE:
    'Non è stato possibile recuperare le informazioni relative allo step, riprova più tardi',

  DESKTOP_BPM_NEXT_TASK_FETCH_ERROR_TITLE: 'Errore',
  DESKTOP_BPM_NEXT_TASK_FETCH_ERROR_MESSAGE:
    'Non è stato possibile recuperare il prossimo task della pratica',
  DESKTOP_BPM_TASK_INFO_FETCH_ERROR_TITLE: 'Errore',
  DESKTOP_BPM_TASK_INFO_FETCH_ERROR_MESSAGE:
    'Non è stato possibile recuperare le informazioni relative al task corrente',

  BPM_COMUNICAZIONE_FORM_OGGETTO: 'Inserisci breve descrizione oggetto istanza',
  BPM_COMUNICAZIONE_FORM_OGGETTO_PRE:
    'Inserisci una breve descrizione che verrà precompilato nel file da scaricare',

  BPM_COMUNICAZIONE_FORM_NOME_FILE: 'Inserisci nome del documento',
  BPM_COMUNICAZIONE_FORM_CLASSIFICAZIONE:
    'Seleziona una classificazione/fascicolazione',
  BPM_COMUNICAZIONE_FORM_UFFICI: 'Seleziona un ufficio',

  BPM_HEADER_N_PRATICA: 'N. Pratica',
  BPM_HEADER_N_PROTOCOLLO: 'N. Protocollo',
  BPM_HEADER_RICHIEDENTE: 'Richiedente',
  BPM_HEADER_X_CONTO: 'Per conto di',
  BPM_HEADER_DATE_CREATION: 'Data creazione',
  BPM_HEADER_DATE_INOLTRO: 'Data inoltro',
  BPM_HEADER_DATE_PROTOCOLLO: 'Data protocollo',
  BPM_HEADER_PHASE: 'Fase',
  DETAIL_false: 'Vedi dettaglio',
  DETAIL_true: 'Chiudi dettaglio',
  DETAIL_RICHIESTA: 'Richiesta',
  OMESSA_PUBBL: 'l’omessa pubblicazione',
  PUBBL: 'la pubblicazione parziale',

  SEZ_AMM_DISPOSIZIONI_GENERALI: 'Disposizioni Generali',
  SEZ_AMM_ORGANIZZAZIONE: 'Organizzazione',
  SEZ_AMM_CONSULENTI_COLLABORATORI: 'Consulenti e Operatori',
  SEZ_AMM_PERSONALE: 'Personale',
  SEZ_AMM_BANDI_CONCORSO: 'Bandi di Concorso',
  SEZ_AMM_PERFORMANCE: 'Performance',
  SEZ_AMM_ENTI_CONTROLLATI: 'Enti Controllati',
  SEZ_AMM_ATTIVITA_PROCEDIMENTI: 'Attività e procedimenti',
  SEZ_AMM_PROVVEDIMENTI: 'Provvedimenti',
  SEZ_AMM_BANDI_GARA: 'Bandi di Gara e Contratti',
  SEZ_AMM_SOVVENZIONI: 'Sovvenzioni, contributi, sussidi, vantaggi economici',
  SEZ_AMM_BILANCI: 'Bilanci',
  SEZ_AMM_BENI_PATRIMONIO: 'Beni Immobili e Gestione Patrimonio',
  SEZ_AMM_CONTROLLO_RILIEVI: "Controllo e rilievi sull'amministrazione",
  SEZ_AMM_PAGAMENTI: "Pagamenti dell'amministrazione",
  SEZ_AMM_OPERE_PUBBLICHE: 'Opere Pubbliche',
  SEZ_AMM_PIANIFICAZIONE_GOVERNO: 'Pianificazione e governo del territorio',
  SEZ_AMM_INFO_AMBIENTALI: 'Informazioni Ambientali',
  SEZ_AMM_INTERVENTI: 'Interventi straordinari e di emergenza',
  SEZ_AMM_ALTRO: 'Altri Contenuti',
  SEZ_AMM_null: '---',

  PROCEDIBILE: 'Procedibile',
  NON_PROCEDIBILE: 'Non procedibile',
  NON_VALIDA: 'Non valida',
  ACCETTO: 'Accetto',
  NON_ACCETTO: 'Non accetto',
  REGOLARIZZATA: 'Regolarizzata',
  NON_REGOLARIZZATA: 'Non regolarizzata',
  INTEGRAZIONE_NON_IDONEA: 'Integrazione non idonea',
  VALIDA: 'Istanza ammissibile: comunicazione avvio',
  VALIDA_AVVIO_PROC:
    'Istanza ammissibile: comunicazione avvio procedimento attraverso notifica preliminare',
  NON_AMMISSIBILE: 'Istanza non valida: Nota con motivazioni di chiusura',
  ALTRO_PROCEDIMENTO:
    'Istanza gestita come altro procedimento: nota con motivazioni di chiusura',
  ESITO_POSITIVA: 'Positivo',
  ESITO_NEGATIVA: 'Negativo',
  ESITO_POSITIVO: 'Approvata',
  ESITO_NEGATIVO: 'Bocciata',
  POSITIVA: 'Positivo',
  NEGATIVA: 'Negativo',
  POSITIVO: 'Positivo',
  NEGATIVO: 'Negativo',
  RICHIESTA_INTEGRAZIONE: 'Richiedere integrazione',
  INVIO_PREAVVISO: 'Invio di preavviso',
  ARCHIVIAZIONE: 'Da archiviare',
  ISTANZA_COMPLETA: 'Istanza completa',

  SINGLE_SELECT_VERIFICA_INTEGRAZIONI_TITLE_REGOLARIZZATA: 'Regolarizzata',
  SINGLE_SELECT_VERIFICA_INTEGRAZIONI_TITLE_NON_REGOLARIZZATA:
    'Non regolarizzata',
  DESKTOP_BPM_COMUNICAZIONE_FILE_DOWNLOAD_ERROR_TITLE: 'Errore',
  DESKTOP_BPM_COMUNICAZIONE_FILE_DOWNLOAD_ERROR_MESSAGE:
    'Non è stato possibile eseguire il download del documento, riprova più tardi',
  DESKTOP_BPM_COMUNICAZIONE_FILE_UPLOAD_ERROR_TITLE: 'Errore',
  DESKTOP_BPM_COMUNICAZIONE_FILE_UPLOAD_ERROR_MESSAGE:
    "Non è stato possibile eseguire l'upload del documento, riprova più tardi",

  TABLE_ICON_TITLE_VIEW_DETAIL_COM: 'Vedi dettaglio comunicazione',

  SINGLE_SELECT_VERIFICA_SOTTOSCRIZIONE_TITLE: 'Validità istanza',
  SINGLE_SELECT_VERIFICA_PROCEDIBILITA_TITLE: 'Procedibilità istanza',
  SINGLE_SELECT_VERIFICA_SOTTOSCRIZIONE_LONG_TEXT:
    'Testo lungo validità istanza',
  SINGLE_SELECT_VERIFICA_PROCEDIBILITA_LONG_TEXT:
    'Testo lungo procedibilita istanza',
  SINGLE_SELECT_VERIFICA_SOTTOSCRIZIONE_FORM_LABEL: 'L’istanza presentata è',
  SINGLE_SELECT_VERIFICA_PROCEDIBILITA_FORM_LABEL: 'L’istanza presentata è',
  SINGLE_SELECT_VERIFICA_INTEGRAZIONI_PREAVVISO_TITLE: 'Verifica preavviso',
  SINGLE_SELECT_VERIFICA_INTEGRAZIONI_PREAVVISO_LONG_TEXT:
    "Verifica l'integrazione fatto dopo il preavviso di diniego",
  SINGLE_SELECT_VERIFICA_INTEGRAZIONI_PREAVVISO_FORM_LABEL:
    'La pratica risulta',

  SINGLE_SELECT_VERIFICA_INTEGRAZIONI_TITLE: 'Verifica integrazione',
  SINGLE_SELECT_VERIFICA_INTEGRAZIONI_LONG_TEXT:
    "Verifica l'integrazione fatto dopo la richiesta",
  SINGLE_SELECT_VERIFICA_INTEGRAZIONI_FORM_LABEL: 'La pratica risulta',

  SINGLE_SELECT_ACCETTAZ_RINUNCIA_TITLE: 'Approva rinuncia',
  SINGLE_SELECT_ACCETTAZ_RINUNCIA_LONG_TEXT: 'Approva rinuncia testo lungo',
  SINGLE_SELECT_ACCETTAZ_RINUNCIA_FORM_LABEL: 'Seleziona una scelta',
  COM_NON_VALIDA_TITLE: 'Comunicazione',
  COM_NON_VALIDA_LONG_TEXT: 'Test lungo comunicazione non validità',
  COM_NON_VALIDA_CLASS_TITOLO_SELECT: 'Classificazione/Fascicolazione',
  COM_ACCETTAZ_RINUNCIA_TITLE: 'Comunica esito richiesta di rinuncia',
  COM_ACCETTAZ_RINUNCIA_LONG_TEXT:
    'Comunica  esito richiesta di rinuncia testo lungo',
  COM_ACCETTAZ_RINUNCIA_CLASS_TITOLO_SELECT: 'Classificazione/fascicolazione:',
  COM_NON_PROC_TITLE: 'Comunicazione',
  COM_NON_PROC_LONG_TEXT: 'Comunica non procedibilità',
  COM_NON_PROC_CLASS_TITOLO_SELECT: 'Classificazione/Fascicolazione',
  VALIDATIONS_FILE_EXTENSION: 'Formato file non valido',

  COMUNICAZIONE_STATO_INVIATA: 'In attesa di risposta',
  COMUNICAZIONE_STATO_NON_INVIATA: 'Non inviata',
  COMUNICAZIONE_STATO_NON_ACCETTATA: 'Non accettata',
  COMUNICAZIONE_STATO_PARZIALMENTE_ACCETTATA: 'Parzialmente accettata',
  COMUNICAZIONE_STATO_ACCETTATA: 'Accettata',
  COMUNICAZIONE_STATO_null: '---',
  COMUNICAZIONE_STATO_undefined: '---',
  COMUNICAZIONE_STATO_NON_VISUALIZZATA: 'Non visualizzato',
  COMUNICAZIONE_STATO_VISUALIZZATA: 'Visualizzato',

  DESKTOP_BPM_COMUNICAZIONI_ERROR_DELETE_MESSAGE:
    'Non è stato possibile eliminare la comunicazione selezionata, riprova più tardi',
  DESKTOP_BPM_COMUNICAZIONI_ERROR_INVIO_MESSAGE:
    'Non è stato possibile inviare la comunicazione selezionata, riprova più tardi',
  DESKTOP_BPM_COMUNICAZIONI_ERROR_OPEN_DETAIL_MESSAGE:
    'Non è stato possibile visualizzare il dettaglio della comunicazione selezionata, riprova più tardi',
  DESKTOP_BPM_COMUNICAZIONI_ERROR_CREAZIONE_MESSAGE:
    'Non è stato possibile creare la comunicazione, riprova più tardi',
  DESKTOP_BPM_COMUNICAZIONI_ERROR_CREAZIONE_NON_ACCETTATA_MESSAGE:
    'Non è possibile selezionare un documento senza avere responsabilità',
  DESKTOP_BPM_COMUNICAZIONI_ERROR_CREAZIONE_NO_DOCUMENTO_MESSAGE:
    'Selezionare almeno un documento se si è selezionato parzialmente responsabile o responsabile',

  DESKTOP_BPM_COMUNICAZIONI_ERROR_UFFICIO_DESTINATARIO_MESSAGE:
    'Questo ufficio è già stato selezionata come destinatario principale',
  DESKTOP_BPM_COMUNICAZIONI_ERROR_UFFICIO_DESTINATARIO_INSERITO_MESSAGE:
    'Questo ufficio è già stato inserito',

  DESKTOP_BPM_COMUNICAZIONI_UFF_RICH_COLUMNS_MITTENTE: 'Mittente',
  DESKTOP_BPM_COMUNICAZIONI_UFF_RICH_COLUMNS_DATA_RICEZIONE: 'Data ricezione',
  DESKTOP_BPM_COMUNICAZIONI_UFF_RICH_COLUMNS_DATA_INVIO: 'Data invio risposta',
  DESKTOP_BPM_COMUNICAZIONI_UFF_RICH_COLUMNS_RESP_DATO:
    'Responsabilità del dato',
  DESKTOP_BPM_COMUNICAZIONI_UFF_RICH_TITLE_DETAIL:
    'Vedi comunicazione ricevuta',
  DESKTOP_BPM_COMUNICAZIONI_UFF_RICH_VIEW_RISPOSTA: 'Vedi la tua risposta',
  DESKTOP_BPM_COMUNICAZIONI_UFF_RICH_VIEW_COMUNICAZIONE:
    'Comunicazione selezionata',

  DESKTOP_BPM_COMUNICAZIONI_SUCCESS_INVIO_TITLE: 'Comunicazione inviata',
  DESKTOP_BPM_COMUNICAZIONI_SUCCESS_INVIO_MESSAGE:
    'La comunicazione selezionata è stata correttamente inviata',

  ACTIONS_BPM_INVIA_COMUNICAZIONE_UFF_RICH: 'Invia comunicazione di risposta',
  RESPONSABILITA_DEL_DATO_RESPONSABILE: 'Responsabile',
  RESPONSABILITA_DEL_DATO_PARZ_RESPONSABILE: 'Parzialmente responsabile',
  RESPONSABILITA_DEL_DATO_NON_RESPONSABILE: 'Non responsabile',
  BPM_SAVE_TASK_CONFIRM_TITLE: 'Concludi il task',
  BPM_SAVE_TASK_CONFIRM_MESSAGE:
    'Una volta concluso il task non sarà possibile ritornarci.',

  DESKTOP_BPM_PUBBLICAZIONE_SELECT_IST_LABEL: 'L’istanza presentata è',
  DESKTOP_BPM_PUBBLICAZIONE_SELECT_IST_PLACEHOLDER: 'Selezione tipologia',

  ACCOLTA: 'Pratica chiusa con esito positivo',
  NON_ACCOLTA: 'Pratica chiusa con esito negativo',
  PRESENTAZIONE: 'Presentazione pratica',
  NON_CONFORME: 'Pratica non ammissibile',
  ALTRO_PROC: 'Non accolta: Gestita come altro procedimento',
  RIGETTATA: 'Rigettata',
  PARZIALMENTE_ACCOLTA: 'Parzialmente accolta',
  ISTANZA_IN_DIFFERIMENTO: 'In differimento',
  ISTANZA_IN_CORSO: 'In corso',
  PROVVEDIMENTO_EMESSO: 'Emissione provvedimento in corso',
  RICORSO_NO: 'No',
  RICORSO_RICORSO_ACCOLTO: 'Ricorso accolto',
  RICORSO_RICORSO_RESPINTO: 'Ricorso respinto',
  RICORSO_IN_CORSO: 'In corso',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_SOTTOSCRIZIONE_TITLE:
    'Validità istanza',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_PROCEDIBILITA_TITLE:
    'Procedibilità istanza',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_PROCEDIBILITA_SELECT_LABEL:
    'L’istanza presentata è',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_PROCEDIBILITA_PLACEHOLDER:
    'Scegli procedibilita',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_PROCEDIBILITA_LONG_TEXT:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_NON_VALIDITA:
    'Comunicazione non validità istanza',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_NON_VALIDITA_LONG_TEXT:
    'Lorem ipsum dolor sit amet...',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_NON_VALIDITA_DESTINATARIO:
    'Destinatario comunicazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_RICHIESTA_PUBBLICAZIONE_OPTION_PUBB:
    'Parziale pubblicazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_RICHIESTA_PUBBLICAZIONE_OPTION_OMESSA_PUBB:
    'Omessa pubblicazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_RICHIESTA_TABLE_RICHIESTE_ACCESSO_COLUMNS_SEZIONE:
    'Sezione Amministrazione trasp.',
  DESKTOP_PRATICA_DETAIL_QUADRI_RICHIESTA_TABLE_RICHIESTE_ACCESSO_COLUMNS_RIFERIMENTI:
    'Riferimenti doc./norma',
  DESKTOP_PRATICA_DETAIL_QUADRI_RICHIESTA_RINUNCIA_TITLE:
    'Richiesta di rinuncia',
  DESKTOP_PRATICA_DETAIL_QUADRI_RICHIESTA_RINUNCIA_LONG_TEXT:
    'Testo richiesta di rinuncia',
  DESKTOP_PRATICA_DETAIL_QUADRI_COMUNICAZIONE_RINUNCIA_TITLE:
    'Comunicazione di esito richiesta della rinuncia',
  DESKTOP_PRATICA_DETAIL_QUADRI_COMUNICAZIONE_RINUNCIA_LONG_TEXT:
    'Testo Comunicazione di esito richiesta della rinuncia',
  DESKTOP_PRATICA_DETAIL_QUADRI_ACCETTAZIONE_RINUNCIA_TITLE:
    'Approvazione della rinuncia',
  DESKTOP_PRATICA_DETAIL_QUADRI_ACCETTAZIONE_RINUNCIA_LONG_TEXT:
    'Testo approvazione della rinuncia',
  DESKTOP_PRATICA_DETAIL_QUADRI_DOCUMENTI_TITLE: 'Documenti',

  DESKTOP_PRATICA_DETAIL_QUADRI_REGISTRO_ACCESSI_ISTANZA_LABEL:
    'Numero istanza',
  DESKTOP_PRATICA_DETAIL_QUADRI_REGISTRO_ACCESSI_DATA_PROTOCOLLO_LABEL:
    'Data protocollo istanza',
  DESKTOP_PRATICA_DETAIL_QUADRI_REGISTRO_ACCESSI_AMBITO_LABEL: 'Ambito',
  DESKTOP_PRATICA_DETAIL_QUADRI_REGISTRO_ACCESSI_OGGETTO_LABEL: 'Oggetto',
  DESKTOP_PRATICA_DETAIL_QUADRI_REGISTRO_ACCESSI_UFFICIO_DETENTORE_LABEL:
    'Ufficio Detentore del Dato',
  DESKTOP_PRATICA_DETAIL_QUADRI_REGISTRO_ACCESSI_ESITO_LABEL: 'Esito',
  DESKTOP_PRATICA_DETAIL_QUADRI_REGISTRO_ACCESSI_DATA_ESITO_LABEL: 'Data esito',
  DESKTOP_PRATICA_DETAIL_QUADRI_REGISTRO_ACCESSI_RICORSO_LABEL:
    'Ricorso al Giudice Amministrativo',

  DESKTOP_PRATICA_DETAIL_QUADRI_COM_AVVIO_PROC_DOC: 'Documento',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_NON_PROC_DOC: 'Documento',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_PUBB_TITLE:
    'Comunicazione avvenuta pubblicazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_PUBB_LONG_TEXT: 'Lorem ipsum...',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_PUBB_DESTINATARIO:
    'Destinatario comunicazione',

  DESKTOP_PRATICA_DETAIL_TASK_APERTURA: 'Apertura pratica',
  DESKTOP_PRATICA_DETAIL_TASK_VERIFICA_SOTTOSCRIZIONE: 'Verifica requisiti',
  DESKTOP_PRATICA_DETAIL_TASK_ACQUISIZIONE_PARERE_ENTI_ESTERNI:
    'Acquisizione parere Enti Esterni',
  DESKTOP_PRATICA_DETAIL_TASK_VERIFICA_PROCEDIBILITA: 'Verifica procedibilità',
  DESKTOP_PRATICA_DETAIL_TASK_VERIFICA_PREAVVISO: 'Verifica preavviso',
  DESKTOP_PRATICA_DETAIL_TASK_AVVIO_PROCEDIMENTO: 'Avvio procedimento',
  DESKTOP_PRATICA_DETAIL_TASK_APERTURA_REGISTRO_ACCESSI:
    'Apertura registro accessi',
  DESKTOP_PRATICA_DETAIL_TASK_COMUNICAZIONE_PUBBLICAZIONE:
    'Comunicazione pubblicazione',
  DESKTOP_PRATICA_DETAIL_TASK_CHIUSURA_REGISTRO_ACCESSI:
    'Chiusura registro accessi',
  DESKTOP_PRATICA_DETAIL_TASK_COMUNICAZIONE_NON_VALIDITA:
    'Comunicazione non validità',
  DESKTOP_PRATICA_DETAIL_TASK_COMUNICAZIONE_PREAV_DINIEGO:
    'Comunicazione di preavviso diniego',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_AVVIO_PROCEDIMENTO_TITLE:
    'Comunicazione avvio procedimento',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_AVVIO_PROCEDIMENTO_LONG_TEXT:
    'Lorem ipsum dolor sit amet...',
  DESKTOP_PRATICA_DETAIL_QUADRI_ACQUISIZIONE_PARERE_ENTI_ESTERNI_TITLE:
    'Parere Enti Esterni',
  DESKTOP_PRATICA_DETAIL_QUADRI_ACQUISIZIONE_PARERE_ENTI_ESTERNI_LONG_TEXT:
    'Descr. Parere Enti Esterni',
  DESKTOP_PRATICA_DETAIL_QUADRI_ACQUISIZIONE_PARERE_ENTI_ESTERNI_SELECT_LABEL:
    'La richiesta è stata',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_D7_TITLE: 'Modello D7',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_DOC_PROGETTO_TITLE:
    'Progetto dell’opera da realizzare',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_ALTRI_DOC_TITLE:
    'Altri documenti',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_TABLE_COLUMNS_NOME_DOCUMENTO:
    'Nome documento',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_TABLE_COLUMNS_DATA_CARICAMENTO:
    'Data caricamento',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_TABLE_COLUMNS_NOTE: 'Note',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_TABLE_COLUMNS_TIPO_DOCUMENTO:
    'Tipo documento',
  DESKTOP_PRATICA_DETAIL_QUADRI_UPLOAD_ALLEGATI_TITLE: 'Documenti caricati',

  DESKTOP_PRATICA_DETAIL_QUADRI_ELENCO_PORTI_TITLE: 'Porto di riferimento',
  DESKTOP_PRATICA_DETAIL_QUADRI_ELENCO_PORTI_LONG_TEXT:
    'Selezione dai porti disponibili',
  DESKTOP_PRATICA_DETAIL_QUADRI_ELENCO_PORTI_SELECT_LABEL: 'Porto Selezionato',

  DESKTOP_PRATICA_DETAIL_QUADRI_COMUNICAZIONE_GENERICA_DESTINATARI:
    'Destinatari comunicazione:',
  DESKTOP_PRATICA_DETAIL_QUADRI_COMUNICAZIONE_GENERICA_DOCUMENTO: 'Documento:',

  DESKTOP_PRATICA_DETAIL_QUADRI_COM_PREAV_DINIEGO_TITLE:
    'Comunicazione preavviso diniego',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_PREAV_DINIEGO_LONG_TEXT:
    'Lorem ipsum dolor sit amet...',

  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_INTEGRAZIONI_PREAVVISO_TITLE:
    "Verifica l'integrazione post preavviso",
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_INTEGRAZIONI_PREAVVISO_LONG_TEXT:
    "Verifica l'integrazione post preavviso testo",

  IMPIEGATO_TITLE: 'Scelta del responsabile istruttoria',
  MODALE_IMPIEGATO_TITLE: 'Seleziona il resp. istr.',
  IMPIEGATO_LONG_TEXT: 'Testo della scelta del resp. istr.',
  MODALE_RESP_PROC_TITLE: 'Seleziona il responsabile del procedimento',
  RESP_PROC_LONG_TEXT: 'Testo della scelta del responsabile procedimento',
  RESPONSABILE_PROCEDIMENTO_TITLE: 'Scelta del responsabile del procedimento',
  MODALE_RESPONSABILE_PROCEDIMENTO_TITLE:
    'Seleziona il responsabile del procedimento',
  RESPONSABILE_PROCEDIMENTO_LONG_TEXT:
    'Testo della scelta del responsabile procedimento',
  SINGLE_SELECT_ELENCO_PORTI_TITLE: 'Seleziona il porto competente',
  SINGLE_SELECT_ELENCO_PORTI_LONG_TEXT:
    'Seleziona il porto in cui vuoi richiedere il procedimento',
  SINGLE_SELECT_ELENCO_PORTI_FORM_LABEL: 'Seleziona il porto competente',

  DESKTOP_BPM_UPLOAD_D7_TITLE: 'Modello D7',
  DESKTOP_BPM_UPLOAD_PROGETTO_TITLE: 'Progetto dell’opera da realizzare',
  DESKTOP_BPM_UPLOAD_ALTRO_TITLE: 'Altri documenti',

  DESKTOP_BPM_UPLOAD_D7_LONG_TEXT:
    'Inserire il modello D7, in bollo, firmato e compilato in tutte le sue parti.',
  DESKTOP_BPM_UPLOAD_PROGETTO_LONG_TEXT:
    "Inserire un unico file con la relazione tecnica descrittiva e la planimetria inerente all'area oggetto dell'intervento.",
  DESKTOP_BPM_UPLOAD_ALTRO_LONG_TEXT: 'Puoi inserire ulteriori documenti.',

  DESKTOP_BPM_UPLOAD_ALLEGATI_TABLE_COLUMNS_NOME_DOC: 'Nome documento',
  DESKTOP_BPM_UPLOAD_ALLEGATI_TABLE_COLUMNS_DATA_CARICAMENTO:
    'Data caricamento',
  DESKTOP_BPM_UPLOAD_ALLEGATI_BUTTON_DELETE_DOCUMENT_TITLE: 'Elimina',
  DESKTOP_BPM_UPLOAD_ALLEGATI_BUTTON_TEMPLATE: 'Scarica template',
  DESKTOP_BPM_UPLOAD_ALLEGATI_TABLE_COLUMNS_NOTE: 'Note',
  DESKTOP_BPM_UPLOAD_ALLEGATI_BUTTON_UPLOAD: 'Carica doc',
  DESKTOP_BPM_UPLOAD_ALLEGATI_FORM_INPUTS_FILE_NOTE_LABEL: 'Note aggiuntive',
  DESKTOP_BPM_UPLOAD_ALLEGATI_FORM_INPUTS_FILE_NOTE_PLACEHOLDER:
    'Inserisci note',
  DESKTOP_BPM_UPLOAD_ALLEGATI_FORM_INPUTS_FILE_NAME_LABEL: 'Nome file',
  DESKTOP_BPM_UPLOAD_ALLEGATI_FORM_INPUTS_FILE_NAME_PLACEHOLDER:
    'Inserisci nome file',
  DESKTOP_BPM_UPLOAD_ALLEGATI_MODAL_BUTTON_UPLOAD: 'Carica',

  DESKTOP_BPM_UPLOAD_ALLEGATI_FORM_INPUTS_FILE_PLACEHOLDER:
    'Puoi allegare solo file .pdf, .jpg, .png, .tiff, .p7m (dimensione massima 10mb)',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_COMPANY_NAME: 'Denominazione azienda',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_CODE: 'Codice identificativo',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_PIVA: 'Partita Iva',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_ADDRESS:
    'Indirizzo sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_CAP: 'CAP sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_NATION: 'Nazione sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_PROVINCE:
    'Provincia sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_CITY: 'Comune sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_FISCAL_ADDRESS:
    'Indirizzo sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_FISCAL_CAP: 'CAP sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_FISCAL_CITY: 'Nazione sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_FISCAL_PROVINCE:
    'Provincia sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_FISCAL_NATION: 'Comune sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_CC: 'Sede camera di commercio',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_DATE_I_CC:
    'Data iscrizione camera di commercio',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_TIPO_SOCIETA_CC: 'Tipo società',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_TIPO_AZIENDA_CC: 'Tipo azienda',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_CAPITALE_SOCIALE: 'Capitale sociale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SCADENZA_SOCIETA:
    'Data scadenza società',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_DURATA_SOCIETA: 'Durata società',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_PROVINCE_REA: 'Provincia REA',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_NUMERO_ISCRIZIONE_AA:
    'Numero iscrizione Albo Autotrasporti',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_NUMERO_ISCRIZIONE_REN:
    'Numero iscrizione REN',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_PEC: 'PEC',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_REPRESENTED_FISCAL_CODE:
    'CF rappresentante',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_REPRESENTED_NAME:
    'Nome rappresentante',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_REPRESENTED_SURNNAME:
    'Cognome rappresentante',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_FISCAL_CODE: 'Codice Fiscale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_NAME: 'Nome',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SURNAME: 'Cognome',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_DATE_OF_BIRTH: 'Data di nascita',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEX: 'Sesso',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_FUNCTION: 'Funzione',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_NATION: 'Nazione di nascita',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_PROVINCE: 'Provincia di nascita',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_CITY: 'Comune di nascita',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_RESIDENCE: 'Residenza',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_ADDRESS: 'Indirizzo di residenza',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_CAP: 'CAP di residenza',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_NATION_RESIDENCE:
    'Nazione di residenza',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_PROVINCE_RESIDENCE:
    'Provincia di residenza',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_CITY_RESIDENCE: 'Comune di residenza',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_PHONE: 'Telefono',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_CELLULARE: 'Cellulare',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_EMAIL: 'Email',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_PEC: 'Pec',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_I_CC:
    'Iscrizione alla camera di commercio',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_REA: 'Numero REA',
  DESKTOP_DELEGHE_DETAIL_TABS_DOCUMENTS_LIST_COLUMNS_NAME: 'Nome allegato',
  DESKTOP_DELEGHE_DETAIL_TABS_DOCUMENTS_LIST_COLUMNS_APPLICANT_NAME:
    'Nome richiedente',
  DESKTOP_DELEGHE_DETAIL_TABS_DOCUMENTS_LIST_COLUMNS_DELEGATE_NAME:
    'Nome delegato',
  DESKTOP_DELEGHE_DETAIL_TABS_DOCUMENTS_LIST_COLUMNS_UPLOAD_DATE:
    'Data caricamento',
  DESKTOP_DELEGHE_DETAIL_TABS_DOCUMENTS_TOASTR_ERROR_FETCH_TITLE: 'Errore',
  DESKTOP_DELEGHE_DETAIL_TABS_DOCUMENTS_TOASTR_ERROR_FETCH_MESSAGR:
    'Non è stato possibile recuperare gli allegati della delega, riprova più tardi',
  DESKTOP_DELEGHE_DETAIL_TABS_DOCUMENTS_TOASTR_ERROR_DOWNLOAD_TITLE: 'Errore',
  DESKTOP_DELEGHE_DETAIL_TABS_DOCUMENTS_TOASTR_ERROR_DOWNLOAD_MESSAGE:
    'Non è stato possibile scaricare il documento selezionato, riprova più tardi',

  DESKTOP_DELEGHE_DETAIL_TITLE: 'Dati della delega selezionata',
  DESKTOP_DELEGHE_DETAIL_DELEGATO: 'Delegato',
  DESKTOP_DELEGHE_DETAIL_DELEGANTE: 'Delegante',
  DESKTOP_DELEGHE_DETAIL_DOCUMENTI: 'Allegati della delega',

  DESKTOP_BPM_COLUMNS_NAME: 'Nome',
  DESKTOP_BPM_COLUMNS_SURNAME: 'Cognome',
  DESKTOP_BPM_COLUMNS_FISCAL_CODE: 'Codice Fiscale',

  BUTTON_RESP_PROC_TITLE: 'Inserisci Resp. Proc.',
  BUTTON_IMPIEGATO_TITLE: 'Inserisci Resp. Istr.',
  TABLE_ICON_TITLE_REMOVE: 'Rimuovi',
  VALIDATIONS_REQUIRED_DOCUMENTO: 'Documento obbligatorio',
  VALIDATIONS_REQUIRED_CHECK_MULTIPLO: 'Selezionare almeno un campo',

  DESKTOP_BPM_SELECT_FORM_INPUTS_MOTIVAZIONE_LABEL: 'Motivazione',
  DESKTOP_BPM_SELECT_FORM_INPUTS_MOTIVAZIONE_PLACEHOLDER:
    'Inserisci motivazione alla tua scelta',

  SINGLE_SELECT_ACCETTA_RESP_PROC_TITLE:
    "Scelta per l'accettazione dell'incarico come responsabile del procedimento",
  SINGLE_SELECT_ACCETTA_RESP_PROC_LONG_TEXT: 'Descrizione sulla accettazione',
  SINGLE_SELECT_ACCETTA_RESP_PROC_FORM_LABEL: 'Scegli opzione',
  SINGLE_SELECT_ACCETTA_RESP_ISTR_TITLE:
    "Scelta per l'accettazione dell'incarico come responsabile dell'istruttoria",
  SINGLE_SELECT_ACCETTA_RESP_ISTR_LONG_TEXT: 'Descrizione sulla accettazione',
  SINGLE_SELECT_ACCETTA_RESP_ISTR_FORM_LABEL: 'Scegli opzione',

  SINGLE_SELECT_PROCEDIBILITA_ISTANZA_TITLE: 'Verifica procedibilità istanza',
  SINGLE_SELECT_PROCEDIBILITA_ISTANZA_LONG_TEXT:
    'Desc. verifica procedibilità istanza',
  SINGLE_SELECT_PROCEDIBILITA_ISTANZA_FORM_LABEL: 'L’istanza presentata è',

  SINGLE_SELECT_VALIDITA_ISTANZA_TITLE: 'Verifica validità istanza',
  SINGLE_SELECT_VALIDITA_ISTANZA_LONG_TEXT: 'Desc. verifica validità istanza',
  SINGLE_SELECT_VALIDITA_ISTANZA_FORM_LABEL: 'L’istanza presentata è',

  TASK_NEXT_BUTTON_COMUNICA_RICHIESTA_INTEGRAZIONE_DESC:
    'Comunicazione richiesta di integrazione',
  TASK_NEXT_BUTTON_COMUNICA_RICHIESTA_INTEGRAZIONE: 'Richiedi integrazione',
  DESKTOP_BPM_STEP_NAME_COMUNICAZIONE_RICHIESTA_INTEGRAZIONE:
    'Richiesta integrazione',
  DESKTOP_BPM_TASK_NAME_BUTTON_COMUNICA_RICHIESTA_INTEGRAZIONI:
    'Comunicazione di richiesta integrazione',
  DESKTOP_BPM_STEP_NAME_COMUNICAZIONE_PREAV_DINIEGO: 'Preavviso diniego',
  DESKTOP_BPM_TASK_NAME_BUTTON_COMUNICA_PREAV_DINIEGO:
    'Comunicazione di preavviso diniego',

  DESKTOP_BPM_STEP_NAME_INSERIMENTO_DOC_INTEGRAZIONI:
    'Inserisci documenti di integrazione',
  DESKTOP_BPM_TASK_NAME_BUTTON_INSERISCI_INTEGRAZIONI: 'Inserisci integrazione',

  RICHIESTA_INTEGRAZIONE_TITLE: 'Comunicazione per richiedere integrazione',
  RICHIESTA_INTEGRAZIONE_LONG_TEXT:
    'Descrizione della comunicazione richiesta di integrazione',
  RICHIESTA_INTEGRAZIONE_CLASS_TITOLO_SELECT: 'Classificazione/Fascicolazione',

  TASK_NEXT_BUTTON_COMUNICA_PREAV_DINIEGO_DESC:
    'Comunicazione preavviso di diniego',
  TASK_NEXT_BUTTON_COMUNICA_PREAV_DINIEGO: 'Comunicazione Preav. Din.',

  DESKTOP_BPM_STEP_NAME_COMUNICAZIONE_AUTORIZZAZIONE: 'Crea Comunicazione',
  DESKTOP_BPM_TASK_NAME_BUTTON_COMUNICA_AUTORIZZAZIONE:
    'Comunica autorizzazione',
  DESKTOP_BPM_STEP_NAME_COMUNICAZIONE_DINIEGO: 'Crea Comunicazione',
  DESKTOP_BPM_TASK_NAME_BUTTON_COMUNICA_DINIEGO: 'Comunica diniego',

  COM_AUTORIZZAZIONE_TITLE: 'Crea comunicazione di autorizzazione',
  COM_AUTORIZZAZIONE_LONG_TEXT: 'Comunicazione autorizzazione descrizione',
  COM_AUTORIZZAZIONE_CLASS_TITOLO_SELECT: 'Classificazione/Fascicolazione',
  COM_DINIEGO_TITLE: 'Crea comunicazione di diniego',
  COM_DINIEGO_LONG_TEXT: 'Descrizione di comunicazione di diniego',
  COM_PREAV_DINIEGO_TITLE: 'Comunicazione preavviso diniego',
  COM_PREAV_DINIEGO_LONG_TEXT: 'Desc. comunicazione preavviso diniego',
  PREAVV_DINIEGO_CLASS_TITOLO_SELECT: 'Classificazione/Fascicolazione',
  COM_DINIEGO_CLASS_TITOLO_SELECT: 'Classificazione/Fascicolazione',

  DESKTOP_BPM_UPLOAD_RELAZIONE_ENTI_ESTERNI_TITLE:
    'Carica parere degli Enti Esterni',
  DESKTOP_BPM_UPLOAD_RELAZIONE_ENTI_ESTERNI_LONG_TEXT:
    'Desc. documenti enti esterni',

  DESKTOP_BPM_UPLOAD_INTEGRAZIONI_DINIEGO_TITLE: 'Integra i documenti',
  DESKTOP_BPM_UPLOAD_INTEGRAZIONI_DINIEGO_LONG_TEXT:
    'Integra i documenti per evitare il diniego',
  DESKTOP_BPM_UPLOAD_INTEGRAZIONI_TITLE: 'Integra i documenti',
  DESKTOP_BPM_UPLOAD_INTEGRAZIONI_LONG_TEXT:
    'Integra i documenti per evitare il diniego',
  SINGLE_SELECT_ACQUISIZIONE_PARERE_TITLE: 'Parere Enti Esterni',
  SINGLE_SELECT_ACQUISIZIONE_PARERE_LONG_TEXT: 'Descr. Parere Enti Esterni',
  SINGLE_SELECT_ACQUISIZIONE_PARERE_FORM_LABEL: 'La richiesta è stata',

  DESKTOP_PRATICA_DETAIL_QUADRI_COM_PUBBLICAZIONE_TITLE:
    'Comunicazione avvenuta pubblicazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_PUBBLICAZIONE_LONG_TEXT: 'Lorem ipsum...',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_PUBBLICAZIONE_DESTINATARIO:
    'Destinatario comunicazione',
  DESKTOP_PRATICA_DETAIL_TASK_INOLTRO: 'Inoltra pratica',
  DESKTOP_PRATICA_DETAIL_TASK_COMUNICAZIONI_FO:
    'Comunicazioni tra Responsabili e Operatori',
  DESKTOP_PRATICA_DETAIL_TASK_RINUNCIA: 'Comunicazione di rinuncia',
  DESKTOP_PRATICA_DETAIL_TASK_APPROVA_RINUNCIA: 'Approvazione della rinuncia',
  DESKTOP_PRATICA_DETAIL_TASK_COMUNICAZIONE_FINALE: 'Comunicazione finale',

  DESKTOP_BPM_UPLOAD_DOC_CHIUSURA_TITLE: 'Documento di chiusura',
  COM_FINALE_TITLE: 'Comunicazione finale',
  COM_FINALE_LONG_TEXT: 'Invia la comunicazione di fine pratica',
  DESKTOP_BPM_UPLOAD_DOC_CHIUSURA_LONG_TEXT:
    'È possibile inserire un documento di chiusura',

  DESKTOP_DELEGHE_DETAIL_RICHIEDENTE: 'Richiedente',

  QUADRO_PROCEDIBILITA_ISTANZA_LABEL: 'Verifica procedibilità istanza',

  ESITO_undefined: '---',
  ESITO_ACCOLTA: 'Accolto',
  ESITO_ISTANZA_NON_CONFORME: 'Non conforme',
  ESITO_NON_ACCOLTA: 'Non accolta',
  ESITO_DIVIETO_PROSECUZIONE_ATTIVITA: 'Divieto prosecuzione attività',
  ESITO_SCADUTA: 'Scaduta',

  TASK_NEXT_BUTTON_COMUNICA_FO_DESC: "Comunica all'Operatore",
  TASK_NEXT_BUTTON_COMUNICA_FO: 'Comunica',
  DESKTOP_BPM_STEP_NAME_COMUNICAZIONE_FO: 'Comunicazione',
  DESKTOP_BPM_TASK_NAME_BUTTON_COMUNICA_FO: "Comunicazioni con l'Operatore",

  TASK_NEXT_BUTTON_COMUNICA_BO_DESC: 'Comunica al Responsabile',
  TASK_NEXT_BUTTON_COMUNICA_BO: 'Comunica',
  TASK_NEXT_BUTTON_INSERISCI_INTEGRAZIONI_DESC:
    "Effettua l'integrazione richiesta",
  TASK_NEXT_BUTTON_INSERISCI_INTEGRAZIONI: 'Inserisci integrazione',
  TASK_NEXT_BUTTON_VERIFICA_INTEGRAZIONI_DESC: 'Verifica integrazione inserita',
  TASK_NEXT_BUTTON_VERIFICA_INTEGRAZIONI: 'Verifica integrazione',
  DESKTOP_BPM_STEP_NAME_COMUNICAZIONE_BO: 'Comunicazione',
  DESKTOP_BPM_TASK_NAME_BUTTON_COMUNICA_BO: 'Comunicazioni con il Responsabile',

  QUADRO_PRATICHE_COM_ADD_BUTTON: 'Aggiungi comunicazione',
  QUADRO_PRATICHE_COM_CLOSE_BUTTON: 'Chiudi comunicazione',

  DESKTOP_BPM_COMUNICAZIONE_TITLE_BO: 'Crea Comunicazione per il Responsabile',
  DESKTOP_BPM_COMUNICAZIONE_SUBTITLE_BO:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata',
  DESKTOP_BPM_COMUNICAZIONE_TITLE_FO: "Crea Comunicazione per l'Operatore",
  DESKTOP_BPM_COMUNICAZIONE_SUBTITLE_FO:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata',
  DESKTOP_BPM_COMUNICAZIONE_TITLE_RESPONSE: 'Rispondi alla comunicazione',
  DESKTOP_BPM_COMUNICAZIONE_SUBTITLE_RESPONSE:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata',

  TABLE_COM_ICON_TITLE_VIEW_DETAIL: 'Vedi dettaglio comunicazione',
  QUADRO_PRATICHE_COMUNICAZIONE_INVIATA_MODALE_TITLE: 'Comunicazione',
  QUADRO_PRATICHE_COMUNICAZIONE_RISPOSTA_MODALE_TITLE:
    'Comunicazione di risposta',
  QUADRO_PRATICHE_COMUNICAZIONE_NOT_VIEW: 'Da leggere',
  QUADRO_PRATICHE_COMUNICAZIONE_VIEW: 'Letto',
  QUADRO_PRATICHE_COMUNICAZIONE_RESPONSE: 'Rispondi',

  QUADRO_PRATICHE_COM_MODALE_OGGETTO: 'Oggetto comunicazione',
  QUADRO_PRATICHE_COM_MODALE_TABLE_DOC_TITLE: 'Documenti caricati',
  QUADRO_PRATICHE_COM_MODALE_MITTENTE: 'Mittente',
  QUADRO_PRATICHE_COM_MODALE_DESTINATARIO: 'Destinatario',
  QUADRO_PRATICHE_COM_MODALE_DOC: 'File comunicazione',
  QUADRO_PRATICHE_COM_MODALE_DOC_SCARICA: 'Scarica doc',
  QUADRO_PRATICHE_COM_MODALE_NUM_PROT: 'Numero protocollo',
  QUADRO_PRATICHE_COM_MODALE_DATA_PROT: 'Data protocollazione',
  QUADRO_PRATICHE_COM_MODALE_CLASS: 'Classificazione',
  QUADRO_PRATICHE_COM_MODALE_TABLE_UFFICI_TITLE: 'Uffici per conoscenza',
  DESKTOP_PRATICHE_COM_MODAL_DENOMINAZIONE: 'Denominazione',
  DESKTOP_PRATICHE_COM_MODAL_CODICE: 'Cod. uffici',

  QUADRO_PRATICHE_COM_TABELLA_COLUMNS_DESTINATARIO: 'Destinatario',
  QUADRO_PRATICHE_COM_TABELLA_COLUMNS_MITTENTE: 'Mittente',
  QUADRO_PRATICHE_COM_TABELLA_COLUMNS_DATA_INVIO: 'Data invio',
  QUADRO_PRATICHE_COM_TABELLA_COLUMNS_STATO: 'Stato',
  QUADRO_PRATICHE_COM_TABELLA_COLUMNS_GENERATO: 'Creata da',
  QUADRO_PRATICHE_COM_TABELLA_ORIGINE_BO: 'Responsabile',
  QUADRO_PRATICHE_COM_TABELLA_ORIGINE_FO: 'Operatore',
  QUADRO_PRATICHE_COM_TABELLA_COLUMNS_STATO_LETT: 'Stato lettura com.',
  QUADRO_PRATICHE_COM_TABELLA_COLUMNS_STATO_LETT_RISP: 'Stato lettura risposta',

  QUADRO_PRATICA_TABELLA_COM_TRASV_TITLE: 'Comunicazioni trasversali',
  QUADRO_COM_TRASV_TABELLA_COM_INVIATE_TITLE: 'Comunicazioni inviate',
  QUADRO_COM_TRASV_TABELLA_COM_RICEVUTE_TITLE: 'Comunicazioni ricevute',
  QUADRO_COM_TRASV_TABELLA_N_COM_INVIATE_DA_LEGGERE:
    'comunicazioni di risposta da leggere',
  QUADRO_COM_TRASV_TABELLA_N_COM_RICEVUTE_DA_LEGGERE:
    'comunicazioni ricevute da leggere',

  QUADRO_COM_TRASV_STATO_DA_LEGGERE: 'Da leggere',
  QUADRO_COM_TRASV_STATO_LETTA: 'Letta',
  QUADRO_COM_TRASV_COM_RISPOSTA: 'Ha risposto',
  QUADRO_COM_TRASV_STATO_null: '---',

  DESKTOP_PRATICA_COM_ICON_DELETE_DOC_TITLE: 'Cancella documento',
  ACTIONS_BPM_AGGIUNGI_COMUNICAZIONE: 'Crea comunicazione',

  QUADRO_SELEZIONA_ISTANZA_TITLE: 'Pratica per la quale si presenta l’istanza',
  QUADRO_SELEZIONA_ISTANZA_LONG_TEXT: 'Seleziona l’istanza',
  QUADRO_SELEZIONA_ISTANZA_TABLE_COLUMNS_RICHIEDENTE: 'Richiedente',
  QUADRO_SELEZIONA_ISTANZA_TABLE_COLUMNS_X_CONTO_DI: 'Per conto di',
  QUADRO_SELEZIONA_ISTANZA_TABLE_COLUMNS_N_PRATICA: 'N. pratica',
  QUADRO_SELEZIONA_ISTANZA_TABLE_COLUMNS_DATA_PROTOCOLLO: 'Date protocollo',
  QUADRO_SELEZIONA_ISTANZA_TABLE_COLUMNS_FASE: 'Fase',
  DESKTOP_QUADRO_DATI_PRATICA_TITLE: 'Pratica di riferimento',
  DESKTOP_QUADRO_DATI_PRATICA_BUTTON: 'Vedi pratica',

  QUADRO_SELEZIONA_DOCUMENTI_ISTANZA_LONG_TEXT:
    'Alla S.V., in qualità di titolare del potere sostitutivo la pubblicazione di tutti o parte dei documenti e dati per i quali era stata richiesta la pubblicazione sulla sezione “Amministrazione Trasparente”',
  QUADRO_SELEZIONA_DOCUMENTI_ISTANZA_TITLE_TABLE:
    'Seleziona i documenti oggetto della presente istanza',
  QUADRO_SELEZIONA_DOCUMENTI_ISTANZA_ESITO_PRATICA:
    'La pratica selezionata risulta essere',

  DESKTOP_SUGGERIMENTI_TITLE: 'Nota informativa',

  TITLE_NOTA_INFORMATICA: 'Nota informativa',

  DESKTOP_BPM_STEP_NAME_INOLTRO: 'Inoltra pratica',
  DESKTOP_BPM_TASK_NAME_BUTTON_INOLTRO: 'Inoltra pratica',
  TITOLO_SELECT_CLASS_RICHIESTA_ACCESSO_DATI: 'Tenuto conto che a oggi',
  SINGLE_RADIO_LABEL_NO_INTERAMENTE_PUBLL:
    'quanto richiesto non è stato ancora interamente pubblicato nella sezione “Amministrazione trasparente” sul sito istituzionale',

  SINGLE_RADIO_LABEL_PARZ_PUBBL:
    'è stato solo parzialmente pubblicato nella sezione "Amministrazione trasparente" sul sito istituzionale',

  SINGLE_RADIO_LABEL_NO_RISP:
    'non è stata data nessuna risposta dall’Amministrazione',

  DESKTOP_PRATICA_DETAIL_TASK_COMUNICAZIONE_RESP_PROC:
    'Comunica cambio Resp. Proc.',
  DESKTOP_BPM_STEP_NAME_INVIO_COMUNICAZIONE: 'Invia comunicazione',
  DESKTOP_BPM_TASK_NAME_BUTTON_COMUNICA_RESP_PROC:
    'Comunica cambio responsabile procedimento',
  TITLE_PAGE_BUTTON_COMUNICA_RESP_PROC: 'Comunica Resp. Proc.',
  DESKTOP_BPM_COLUMNS_DATE_START: 'Data inizio validità',
  DESKTOP_BPM_COLUMNS_DATE_END: 'Data fine validità',
  COM_RESP_PROC_TITLE:
    'Invia comunicazione del cambio responsabile procedimento',
  COM_RESP_PROC_LONG_TEXT:
    'Invia comunicazione agli interessati per il cambio responsabile',
  COM_RESP_PROC_CLASS_TITOLO_SELECT: 'Classificazione/Fascicolazione',
  DESKTOP_BPM_UPLOAD_SINGLE_DOCUMENT_FORM_INPUT_MOTIVAZIONE_LABEL:
    'Motivazione',
  DESKTOP_BPM_UPLOAD_SINGLE_DOCUMENT_FORM_INPUT_MOTIVAZIONE_PLACEHOLDER:
    'Inserendo qui il testo della motivazione verrà precaricato nel documento da scaricare',
  DESKTOP_BPM_SINGLE_DOWNLOAD_BUTTON: 'Scarica riepilogo',
  DESKTOP_BPM_DOWNLOAD_RINUNCIA_TITLE: 'Richiesta di rinuncia',
  DESKTOP_BPM_DOWNLOAD_RINUNCIA_LONG_TEXT:
    "Completando l'operazione verrà inviata la richiesta di rinuncia, spetta all'utente di back office accettare o no la richiesta, è possibile inserire una motivazione a tale richiesta.",

  DESKTOP_PRATICHE_FORM_INPUT_DELIVER_DATE_LABEL: 'Data di consegna',
  DESKTOP_BPM_UPLOAD_DELEGA_RITIRO_TITLE: 'Documento di delega al ritiro',
  DESKTOP_BPM_UPLOAD_DELEGA_RITIRO_LONG_TEXT:
    'Inserisci il documento delega al ritiro',
  DESKTOP_BPM_UPLOAD_CONSEGNA_BREVI_MANU_TITLE: 'Documento di consegna',
  DESKTOP_BPM_UPLOAD_CONSEGNA_BREVI_MANU_LONG_TEXT:
    'Inserisci il documento di consegna',
  DESKTOP_PRATICA_DETAIL_QUADRI_CONSEGNA_BREVI_MANU_DOC_TITLE:
    'Documenti consegna brevi manu',
  DESKTOP_PRATICA_DETAIL_QUADRI_CONSEGNA_BREVI_MANU_DOC_LONG_TEXT:
    'Documenti consegna brevi manu',
  DESKTOP_PRATICHE_FORM_INPUT_DELIVER_DATE: 'Data di consegna',
  DESKTOP_PRATICHE_REFERENTE_TITLE: 'Dati referente',

  DESKTOP_BPM_DOWNLOAD_APERTURA_TITLE: 'Inoltro pratica',
  DESKTOP_BPM_DOWNLOAD_APERTURA_LONG_TEXT:
    "Completando l'operazione la pratica verrà inoltrata.",
  DESKTOP_BPM_DELEGA_STATE_SUCCESS_UPDATE_TITLE: 'Stato delega modificato',
  DESKTOP_BPM_DELEGA_STATE_SUCCESS_UPDATE__MESSAGE:
    'Lo stato delega è stato correttamente modificato',
  DESKTOP_PRATICA_DETAIL_TASK_VERIFICA_INTEGRAZIONI: 'Verifica integrazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_INTEGRAZIONI_TITLE:
    "Verifica dell'integrazione effettuata",
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_INTEGRAZIONI_LONG_TEXT:
    "Esito finale dell'integrazione in seguito alla visione dei documenti ricevuti",
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_INTEGRAZIONI_SELECT_LABEL:
    "L'istanza risulta essere",

  DESKTOP_PRATICA_DETAIL_TASK_COMUNICAZIONE_AUTORIZZAZIONE:
    'Comunicazione di Autorizzazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_CHIUSURA_PRATICA_TITLE: 'Fine della pratica',
  DESKTOP_PRATICA_DETAIL_QUADRI_CHIUSURA_PRATICA_LONG_TEXT:
    'Chiusura della pratica con iscrizione al registro imprese, con eventuale nota aggiuntiva:',
  DESKTOP_PRATICA_DETAIL_TASK_INSERISCI_INTEGRAZIONI: 'Integrazione effettuata',
  DESKTOP_PRATICA_DETAIL_QUADRI_CONTROLLO_REQUISITI_TITLE:
    'Controllo dei requisiti',
  DESKTOP_PRATICA_DETAIL_QUADRI_CONTROLLO_REQUISITI_LONG_TEXT:
    'Verifica dei controlli requisiti per il continuo della pratica',
  DESKTOP_PRATICA_DETAIL_QUADRI_CONTROLLO_REQUISITI_SELECT_LABEL: "L'istanza",
  DESKTOP_PRATICA_DETAIL_TASK_COMUNICAZIONE_RICHIESTA_INTEGRAZIONE:
    'Richiesta di integrazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RICHIESTA_INTEGRAZIONE_TITLE:
    'Integrazioni richieste',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RICHIESTA_INTEGRAZIONE_LONG_TEXT:
    "Per il proseguimento della pratica si necessita d'integrare i documenti richiesti nella comunicazione",

  TASK_NEXT_BUTTON_COMUNICA_AUTORIZZAZIONE: 'Comunica autorizzazione',

  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_NAME: 'Nome',
  DESKTOP_BPM_DIPENDENTI_FORM_PLACEHOLDER_NAME: 'Inserisci il nome',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_SURNAME: 'Cognome',
  DESKTOP_BPM_DIPENDENTI_FORM_PLACEHOLDER_SURNAME: 'Inserisci il cognome',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_CF: 'Codice fiscale',
  DESKTOP_BPM_DIPENDENTI_FORM_PLACEHOLDER_CF: 'Inserisci il codice fiscale',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_DATA_NASC: 'Data nascita',
  DESKTOP_BPM_DIPENDENTI_FORM_PLACEHOLDER_DATA_NASC:
    'Inserisci la data di nascita',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_SESSO: 'Sesso',
  DESKTOP_BPM_DIPENDENTI_FORM_PLACEHOLDER_SESSO: 'Inserisci il sesso',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_NAZIONE_NASC: 'Nazione nascita',
  DESKTOP_BPM_DIPENDENTI_FORM_PLACEHOLDER_NAZIONE_NASC:
    'Inserisci la nazione di nascita',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_PROVINCIA_NASC: 'Provincia nascita',
  DESKTOP_BPM_DIPENDENTI_FORM_PLACEHOLDER_PROVINCIA_NASC:
    'Inserisci la provincia di nascita',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_COMUNE_NASC: 'Comune di nascita',
  DESKTOP_BPM_DIPENDENTI_FORM_PLACEHOLDER_COMUNE_NASC:
    'Inserisci il comune di nascita',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_EMAIL: 'Email',
  DESKTOP_BPM_DIPENDENTI_FORM_PLACEHOLDER_EMAIL: 'Inserisci la email',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_TIPO_DOC: 'Tipo documento',
  DESKTOP_BPM_DIPENDENTI_FORM_PLACEHOLDER_TIPO_DOC:
    'Inserisci il tipo documento',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_NUM_DOC: 'Numero documento',
  DESKTOP_BPM_DIPENDENTI_FORM_PLACEHOLDER_NUM_DOC:
    'Inserisci il numero documento',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_TIPO_CONTRATTI: 'Tipo contratto',
  DESKTOP_BPM_DIPENDENTI_FORM_PLACEHOLDER_TIPO_CONTRATTI:
    'Inserisci il tipo contratto',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_LIVELLO: 'Livello',
  DESKTOP_BPM_DIPENDENTI_FORM_PLACEHOLDER_LIVELLO: 'Inserisci il livello',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_MANSIONE: 'Mansione',
  DESKTOP_BPM_DIPENDENTI_FORM_PLACEHOLDER_MANSIONE: 'Inserisci la mansione',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_SCADENZA_CONTRATTO: 'Scadenza contratto',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_DATA_ASSUNZIONE: 'Data assunzione',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_DATA_DISTACCO_DAL: 'Data distacco dal',
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_DATA_DISTACCO_AL: 'Data distacco al',

  DESKTOP_BPM_DIPENDENTI_DETAIL_TITLE: 'Dipendenti',
  DESKTOP_BPM_DIPENDENTI_DETAIL_TEXT:
    'Sezione contente la lista dei dipendenti che eseguiranno i lavori',

  DESKTOP_BPM_DIPENDENTI_BUTTONO_ADD_DIPENDENTE: 'Aggiungi dipendente',
  DESKTOP_BPM_DIPENDENTI_TITLE_SEARCH_DIPENDENTE:
    'Se il dipendente è già presente in anagrafica cercalo tramite codice fiscale per compilare automaticamente i campi',
  DESKTOP_DELEGHE_STEP_DATA_TABS_PERSON_DATA_FORM_CF_LABEL: 'Codice Fiscale',
  DESKTOP_BPM_DIPENDENTI_FORM_SEARCH_LABEL_CF: 'Cerca per codice fiscale',

  DESKTOP_BPM_DIPENDENTI_SUCCESS_TITLE: 'Operazione completata',
  DESKTOP_BPM_DIPENDENTI_ADD_DIPENDENTE_SUCCESS_TEXT:
    'Il dipendente è stato inserito',
  DESKTOP_BPM_DIPENDENTI_EDIT_DIPENDENTE_SUCCESS_TEXT:
    'Il dipendente è stato modificato',
  DESKTOP_BPM_DIPENDENTI_DELETE_DIPENDENTE_SUCCESS_TEXT:
    'Il dipendente è stato rimosso',
  DESKTOP_BPM_DIPENDENTI_DETAIL_DIPENDENTE_TITLE:
    'Dettaglio del dipendente selezionato',

  DESKTOP_BPM_DIPENDENTI_DELETE_TITLE: 'Rimuovi dipendente',
  DESKTOP_BPM_DIPENDENTI_DELETE_TEXT:
    'Confermi di voler rimuovere il dipendente selezionato?',

  DESKTOP_BPM_DIPENDENTI_ICON_TITLE_DETAIL: 'Dettaglio',
  DESKTOP_BPM_DIPENDENTI_ICON_TITLE_DELETE: 'Rimuovi',
  DESKTOP_BPM_DIPENDENTI_ICON_TITLE_UPDATE: 'Modifica',

  DESKTOP_BPM_DIPENDENTI_TITLE: 'Gestisci dipendenti',
  DESKTOP_BPM_DIPENDENTI_TEXT:
    'Sezione contente la lista dei dipendenti che eseguiranno i lavori, qui è possibile aggiungere, modificare o rimuovere un dipendente',

  DESKTOP_BPM_DIPENDENTI_FORM_PLACEHOLDER_DESCRIZIONE_ALTRO:
    'Inserisci descrizione',
  DESKTOP_BPM_DIPENDENTI_LABEL_DESCRIZIONE: 'Descrizione',
  DESKTOP_BPM_MULTI_CHECK_SERVIZI_ATTIVITA_LONG_TEXT:
    "Seleziona le attività di cui si vuole richiedere l'autorizzazione",
  DESKTOP_BPM_MULTI_CHECK_SERVIZI_ATTIVITA_TITLE: 'Seleziona le attività',
  DESKTOP_BPM_MULTI_CHECK_AUTODICHIARAZIONI_LONG_TEXT:
    'Seleziona le autodichiarazioni che si vuole sottoscrivere',
  DESKTOP_BPM_MULTI_CHECK_AUTODICHIARAZIONI_TITLE:
    'Seleziona le autodichiarazioni',
  QUADRO_PRATICHE_TABELLA_ENTI_ESTERNI_COLUMNS_DESTINAZIONE: 'Ente esterno',
  QUADRO_PRATICHE_TABELLA_ENTI_TITLE: 'Comunicazioni inviate agli Enti esterni',
  TITLE_PAGE_BUTTON_ACQUISIZIONE_PARERE_ENTI_ESTERNI:
    'Acquisizione parere Enti Esterni',
  DESKTOP_BPM_COMUNICAZIONE_TITLE_ENTI_ESTERNI:
    'Invia richiesta parare ad ente esterno',
  DESKTOP_BPM_COMUNICAZIONE_SUBTITLE_ENTI_ESTERNI:
    "Seleziona l'ente esterno per cui si richiede il parere per la SCIA",
  QUADRO_PRATICHE_TABELLA_ENTI_ESTERNI_COLUMNS_DATA_INVIO: 'Data invio',
  DESKTOP_BPM_COMUNICAZIONI_A_ENTI_ESTERNI_SUCCESS_CREATA_TITLE:
    'Comunicazione inviata',
  DESKTOP_BPM_COMUNICAZIONI_A_ENTI_ESTERNI_SUCCESS_CREATA_MESSAGE:
    'La comunicazione verso ente esterno è stata correttamente inviata',
  QUADRO_PRATICHE_ENTI_ESTERNI_ADD_BUTTON: 'Aggiungi comunicazione',
  QUADRO_PRATICHE_ENTI_ESTERNI_CLOSE_BUTTON: 'Chiudi comunicazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_RISPOSTA: 'Visualizza comunicazioni',
  DESKTOP_PRATICA_DETAIL_QUADRI_COM_ENTI_ESITO_TITLE:
    'Comunicazioni di richiesta parere agli enti esterni e il loro esito finale',
  DESKTOP_PRATICHE_COMUNICAZIONI_ESITO_CREA_COM:
    'Invia il parere del tuo ente per questa pratica',

  DESKTOP_BPM_ESITO_TITLE: 'Comunicazioni di richiesta parere',
  DESKTOP_BPM_COMUNICAZIONI_ESITO_VIEW_COMUNICAZIONE: 'Parere di risposta',
  DESKTOP_BPM_UFF_RICH_COMUNICAZIONE_ESITO: 'Esito',
  DESKTOP_BPM_UFF_RICH_COMUNICAZIONE_MOTIVAZIONE: 'Motivazione',
  DESKTOP_BPM_COMUNICAZIONI_COLUMNS_STATO: 'Stato',
  DESKTOP_BPM_COMUNICAZIONI_COLUMNS_DATA_RICEZIONE: 'Data ricezione',
  DESKTOP_BPM_COMUNICAZIONI_COLUMNS_MITTENTE: 'Mittente',

  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_ESITO: 'Esito',
  DESKTOP_BPM_DIPENDENTI_FORM_PLACEHOLDER_ESITO: "Inserisci l'esito del parere",
  DESKTOP_BPM_DIPENDENTI_FORM_LABEL_MOTIVAZIONE: 'Motivazione',
  DESKTOP_BPM_DIPENDENTI_FORM_PLACEHOLDER_MOTIVAZIONE:
    'Inserisci la motivazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_ASSICURAZIONE_TITLE: 'Contratto assicurativo',
  DESKTOP_PRATICA_DETAIL_READONLY_COMPAGNIA_ASSICURATIVA:
    'Società assicurativa',
  DESKTOP_PRATICA_DETAIL_READONLY_NUMERO_CONTRATTO_ASSICURAZIONE:
    'Numero polizza',
  DESKTOP_PRATICHE_DETAIL_SERVIZI_ATTIVITA_LONG_TEXT:
    'Elenco delle attività richieste nella SCIA',
  DESKTOP_PRATICHE_DETAIL_SERVIZI_ATTIVITA_TITLE: 'Le attività svolte',
  DESKTOP_PRATICHE_DETAIL_AUTODICHIARAZIONI_LONG_TEXT:
    'Elenco delle autodichiarazioni sottoscritte nella SCIA',
  DESKTOP_PRATICHE_DETAIL_AUTODICHIARAZIONI_TITLE:
    'Autodichiarazioni sottoscritte',
  DESKTOP_PRATICHE_PAGAMENTO_TITLE: 'Pagamento',
  DESKTOP_PRATICHE_PAGAMENTO_TEXT:
    'È richiesto il pagamento della reversale per proseguire con la richiesta della SCIA',
  MEZZO_AUTOVEICOLO: 'Autoveicolo',
  MEZZO_MOTOVEICOLO: 'Motoveicolo',
  MEZZO_TRATTORE: 'Trattore',
  MEZZO_MOTRICE: 'Motrice',
  MEZZO_SEMIRIMORCHIO: 'Semirimorchio',
  MEZZO_SEMIRIMORCHIO_CISTERNA: 'Semirimorchio Cisterna',
  MEZZO_AUTOCARRO: 'Autocarro',
  MEZZO_GRU: 'Gru',
  MEZZO_FURGONE: 'Furgone',
  MEZZO_RIMORCHIO: 'Rimorchio',
  MANSIONE_ADDETTO_VIGILANZA: 'Addetto vigilanza',
  MANSIONE_ADSP: 'ADSP',
  MANSIONE_AUTISTA: 'Autista',
  MANSIONE_DIRETTORE: 'Direttore',
  MANSIONE_IMPIEGATO: 'Impiegato',
  MANSIONE_OPERAIO: 'Operaio',
  MANSIONE_PERSONALE_ESTERNO: 'Personale esterno',
  MANSIONE_RESPONSABILE_LEGALE: 'Responsabile legale',
  MANSIONE_RESPONSABILE_TECNICO: 'Responsabile tecnico',
  QUADRO_DATI_PAGAMENTO_NOME_PRATICA: 'Nome pratica',
  QUADRO_DATI_PAGAMENTO_NUMERO_PRATICA: 'Numero pratica',
  QUADRO_DATI_PAGAMENTO_OGGETTO: 'Oggetto',
  QUADRO_DATI_PAGAMENTO_IMPORTO: 'Importo',
  QUADRO_DATI_PAGAMENTO_STATO: 'Stato',
  QUADRO_DATI_PAGAMENTO_BUTTON_GOTO: 'Vai al pagamento',
  QUADRO_DATI_PAGAMENTO_INTESTATARIO: 'Intestatario',
  QUADRO_DATI_PAGAMENTO_CODICE_FISCALE: 'Codice fiscale',
  QUADRO_DATI_PAGAMENTO_DATA_CREAZIONE: 'Data creazione',
  QUADRO_DATI_PAGAMENTO_DATA_PAGAMENTO: 'Data pagamento',
  SINGLE_SELECT_VERIFICA_PAGAMENTO_TITLE: 'Verifica pagamento',
  SINGLE_SELECT_VERIFICA_PAGAMENTO_LONG_TEXT:
    'Seleziona se la reversale è stata correttamente pagata',
  SINGLE_SELECT_VERIFICA_PAGAMENTO_FORM_LABEL: 'Seleziona verifica pagamento',
  SINGLE_SELECT_ISTANZA_COMPLETA_TITLE: 'Istanza completa',
  SINGLE_SELECT_ISTANZA_COMPLETA_LONG_TEXT:
    'Verifica completezza della pratica',
  SINGLE_SELECT_ISTANZA_COMPLETA_FORM_LABEL: 'Seleziona la validità',

  DESKTOP_BPM_STEP_NAME_COMUNICAZIONE_PARERE_ENTI_ESTERNI: 'Inserisci parere',
  DESKTOP_BPM_TASK_NAME_BUTTON_PARERE_ENTE_ESTERNO: 'Richiesta parere per SCIA',
  QUADRO_PRATICHE_TABELLA_ENTI_CARICA_PARERE: 'Parere extrasistema',
  DESKTOP_PRATICA_DETAIL_TASK_VERIFICA_PAGAMENTO: 'Verifica pagamento',
  DESKTOP_BPM_TASK_NAME_BUTTON_VALIDAZIONE_ENTI_ESTERNI:
    'Esito dei pareri degli enti esterni',
  DESKTOP_BPM_STEP_NAME_VALIDAZIONE_ENTI_ESTERNI: 'Esito pareri',
  DESKTOP_BPM_STEP_URL_VALIDAZIONE_ENTI_ESTERNI: 'validazione',
  SINGLE_SELECT_VALIDAZIONE_ENTI_ESTERNI_FORM_LABEL:
    'Esito finale dei pareri esterni:',
  SINGLE_SELECT_VALIDAZIONE_ENTI_ESTERNI_LONG_TEXT:
    "Seleziona l'esito finale dei pareri esterni che si sono ricevuti",
  SINGLE_SELECT_VALIDAZIONE_ENTI_ESTERNI_TITLE:
    'Validazione esito pareri esterni',

  DESKTOP_PRATICA_DETAIL_QUADRI_ESITO_VALIDAZIONE_ENTI_ESTERNI_TITLE:
    'Validazione esito pareri esterni',
  DESKTOP_PRATICA_DETAIL_QUADRI_ESITO_VALIDAZIONE_ENTI_ESTERNI_LONG_TEXT:
    'Esito finale dei pareri esterni che sono stati ricevuti',
  DESKTOP_PRATICA_DETAIL_QUADRI_ESITO_VALIDAZIONE_ENTI_ESTERNI_FORM_LABEL:
    'Esito finale risulta essere:',

  DESKTOP_PRATICA_DETAIL_TASK_VALIDAZIONE_ENTI_ESTERNI:
    'Validazione pareri esterni',
  QUADRO_PRATICHE_COMUNICAZIONE_PARERE_MODALE_TITLE:
    'Comunicazione di richiesta parere',
  QUADRO_PRATICHE_COM_MODALE_MOTIVAZIONE: 'Motivazione',
  QUADRO_PRATICHE_COM_MODALE_ESITO: 'Esito',
  INSERITO_DA_EXTRASISTEMA: 'Extrasistema',
  INSERITO_MANUALMENTE: 'Manualmente',
  TIPO_DI_INSERITO: 'Tipo di inserimento',
  DESKTOP_PRATICHE_RIMBORSO_TITLE: 'Richiedi rimborso',
  DESKTOP_PRATICHE_RIMBORSO_TEXT:
    "Puo richiedere un rimborso pari alla spesa sostenuta per l'avvio di questo procedimento meno le spese di istruttoria. É necessario fornire IBAN e dati dell'intestatario del conto. Dopo un breve controllo dei dati inseriti verrà effettuato un bonifico di rimborso della cifra sottoscritta al conto da lei segnato. Se ci dovessero essere problemi sarà contatto.",
  BPM_QUADRO_RIMBORSO_TOTALE_PAGAMENTO: 'Pagamento effettuato',
  BPM_QUADRO_RIMBORSO_SPESE_ISTRUTTORIA: 'Spese di istruttoria',
  BPM_QUADRO_RIMBORSO_TOTALE_RIMBORSO: 'Totale rimborso',
  DESKTOP_BPM_RIMBORSO_FORM_INPUTS_IBAN_LABEL: 'IBAN',
  DESKTOP_BPM_RIMBORSO_FORM_INPUTS_IBAN_PLACEHOLDER: 'Inserire IBAN',
  DESKTOP_BPM_RIMBORSO_FORM_INPUTS_INTESTATARIO_LABEL:
    "Nome e cognome dell'intestatario del conto",
  DESKTOP_BPM_RIMBORSO_FORM_INPUTS_INTESTATARIO_PLACEHOLDER: 'Nome Cognome',
  VALIDATIONS_PATTERN_NAME: 'Inserire solo caratteri alfabetici',
  VALIDATIONS_PATTERN_IBAN: 'IBAN non valido',
  QUADRO_DATI_PAGAMENTO_ATTENDERE:
    "* se hai già effettuato il pagamento e lo 'Stato pagamento' non cambia, riavvia la pagina. Se non si dovesse aggiornare riprova più tardi se il problema persiste contattare l'autorità competente.",
  DESKTOP_BPM_STEP_URL_ISCRIZIONE_REGISTRO: 'imprese',
  DESKTOP_BPM_STEP_URL_COMUNICAZIONE_PRATICA_NEGATIVA: 'invia-email',
  DESKTOP_BPM_STEP_NAME_COMUNICAZIONE_PRATICA_NEGATIVA:
    'Comunicazione pratica negativa',
  DESKTOP_BPM_TASK_NAME_BUTTON_PRATICA_NEGATIVA:
    'Invio comunicazione di pratioca respinta',
  DESKTOP_INFO_PRATICA_NEGATIVA_DESC_TITLE: 'Comunicazione pratica respinta',
  INFO_PRATICA_NEGATIVA_DESC:
    'La pratica risulta essere con esito Negativo, invia email a tutti gli interessati per comunicare che la pratica è stata respinta. Al conferma del task verrà inviata la comunicazione',
  DESKTOP_BPM_TASK_NAME_BUTTON_ISCRIZIONE_REGISTRO:
    'Iscrizione al registro imprese',
  DESKTOP_BPM_STEP_NAME_ISCRIZIONE_REGISTRO: 'Iscrizione registro',
  DESKTOP_PRATICA_DETAIL_TASK_ISCRIZIONE_REGISTRO:
    'Iscrizione al registro imprese',
  SINGLE_TEXTAREA_ISCRIZIONE_REGISTRO_LABEL: 'Aggiungi nota',
  SINGLE_TEXTAREA_ISCRIZIONE_REGISTRO_TITLE:
    'Iscrizione al registro delle imprese',
  SINGLE_TEXTAREA_ISCRIZIONE_REGISTRO_LONG_TEXT:
    'É possibile inserire una nota aggiuntiva ',

  DESKTOP_PRATICA_DETAIL_QUADRI_CONTROLLO_PAGAMENTO_TITLE:
    'Controllo del pagamento',
  DESKTOP_PRATICA_DETAIL_QUADRI_CONTROLLO_PAGAMENTO_LONG_TEXT:
    "É stato effettuato un controllo sull'esito del pagamento",
  DESKTOP_PRATICA_DETAIL_QUADRI_CONTROLLO_PAGAMENTO_FORM_LABEL:
    'Esito pagamento:',
  OGGETTO_RICHIESTA_PARERE_ESITO:
    "Richiesta parere esito per l'avanzamento di questa pratica",
  RESPONSABILE_ISTRUTTORIA: 'Resp. Istr.',
  SCRIVANIA_PAGAMENTI_LIST_COLUMNS_OGGETTO: 'Oggetto',
  SCRIVANIA_PAGAMENTI_LIST_COLUMNS_DATA: 'Data',
  SCRIVANIA_PAGAMENTI_LIST_COLUMNS_NOME: 'Pratica',
  SCRIVANIA_PAGAMENTI_LIST_COLUMNS_NUMERO: 'N. Pratica',
  SCRIVANIA_PAGAMENTI_LIST_COLUMNS_STATE: 'Stato',
  SCRIVANIA_PAGAMENTI_LIST_COLUMNS_TIPO: 'Tipo',
  SCRIVANIA_PAGAMENTI_LIST_COLUMNS_IMPORTO: 'Importo',
  SCRIVANIA_PAGAMENTI_LIST_COLUMNS_ESITO: 'Esito',
  SCRIVANIA_PAGAMENTI_RIMBORSI: 'Rimborsi',
  SCRIVANIA_PAGAMENTI_RICEVUTE: 'Ricevute',
  SCRIVANIA_PAGAMENTI_SCARICA_RICEVUTA: 'Scarica ricevuta',
  DESKTOP_BPM_DOWNLOAD_BUTTON_INOLTRO_SPID: 'Scarica riepilogo',
  DESKTOP_PRATICA_DETAIL_QUADRI_ESITO_VALIDAZIONE_ENTI_ESTERNI_SELECT_LABEL:
    'Esito finale dei pareri degli enti esterni',
  TIPO_DOCUMENTO_C_IDENTITA: "Carta d'identità",
  TIPO_DOCUMENTO_PATENTE: 'Patente',
  TIPO_DOCUMENTO_PASSAPORTO: 'Passaporto',

  ISCRIZIONE_REGISTRO_TITLE: 'Iscrizione al registro accessi',
  ISCRIZIONE_REGISTRO_LONG_TEXT:
    "Qui è possibile aggiungere una nota nell'iscrizione del reigstro imprese",
  ISCRIZIONE_REGISTRO_FORM_LABEL: 'Aggiungi una nota',
  NOT_SINGLE_TEXTAREA_OGGETTO_ISCRIZIONE_REGISTRO_TITLE:
    'Non è stata inserita nessuna nota',
  DESKTOP_PRATICA_DETAIL_QUADRI_CONTROLLO_PAGAMENTO_SELECT_LABEL:
    'Esito del controllo pagamento',
  DESKTOP_BPM_DOWNLOAD_INOLTRO_PRATICA_TITLE: 'Inotlro pratica',
  DESKTOP_BPM_DOWNLOAD_INOLTRO_PRATICA_LONG_TEXT:
    "Hai effettuato l'accesso tramite spid quindi non è necessario inserire ulteriori documenti. Qui puoi scaricare il riepilogo della richiesta pratica che stai per inoltrare",
  MGU_DELEGA_DETAIL_TABS_SEDE_CC: 'Sede camera di commercio',

  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_LEGALE_ADDRESS:
    'Indirizzo sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_LEGALE_CAP: 'CAP sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_LEGALE_NATION:
    'Nazione sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_LEGALE_PROVINCE:
    'Provincia sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_LEGALE_CITY:
    'Comune sede legale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_FISCALE_ADDRESS:
    'Indirizzo domicilio fiscale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_FISCALE_CAP:
    'CAP domicilio fiscale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_FISCALE_NATION:
    'Nazione domicilio fiscale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_FISCALE_PROVINCE:
    'Provincia domicilio fiscale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_SEDE_FISCALE_CITY:
    'Comune domicilio fiscale',
  DESKTOP_DELEGHE_DETAIL_TABS_DELEGA_DATA_LEGAL_REPRESENTED_FUNCTION:
    'Funzione rappresentante',
  DESKTOP_PRATICHE_ANNO_RIFERIMENTO_SCIA_LABEL: 'Anno di riferimento',
  DESKTOP_PRATICHE_ANNO_RIFERIMENTO_SCIA_PLACEHOLDER: 'YYYY',
  DESKTOP_PRATICHE_TIPOLOGIA_SCIA_PLACEHOLDER: 'Seleziona tipologia',
  DESKTOP_PRATICHE_CREA_TIPOLOGIA_SCIA_TITLE: 'Tipologia SCIA',
  DESKTOP_PRATICHE_TIPOLOGIA_SCIA_LABEL: 'Seleziona tipologia di SCIA',
  DESKTOP_PRATICA_DETAIL_READONLY_ANNO_RIFERIMENTO: 'Anno di riferimento',
  DESKTOP_PRATICA_DETAIL_READONLY_TIPOLOGIA_SCIA: 'Tipologia SCIA',
  ANNUALE:
    "Annuale, la SCIA sarà vaida per l'anno di riferimento inserito, fino al 31 dicembre.",
  TEMPORANEA:
    'Temporanea, la SCIA scadrà dopo 30 giorni dalla data di inoltro.',
  DESKTOP_PRATICA_DETAIL_QUADRI_TIPOLOGIA_SCIA_TITLE: 'Scelta del tipo di SCIA',
  DESKTOP_BPM_STEP_URL_COMUNICAZIONE_ARCHIVIAZIONE: 'comunicazione',
  DESKTOP_PRATICA_DETAIL_TASK_ARCHIVIAZIONE_SCIA: 'Archiviazione SCIA',
  DESKTOP_BPM_STEP_NAME_COMUNICAZIONE_ARCHIVIAZIONE: 'Archiviazione SCIA',
  DESKTOP_BPM_TASK_NAME_BUTTON_ARCHIVIAZIONE_SCIA:
    'Comunicazione di archiviazione',
  DESKTOP_INFO_ARCHIVIAZIONE_SCIA_TITLE:
    'Invia comunicazione di archiviazione pratica',
  INFO_ARCHIVIAZIONE_SCIA:
    'La pratica risulta essere non procedibnile, invia email a tutti gli interessati per comunicare che la pratica è stata archiviata. Al conferma del task verrà inviata la comunicazione',
  DESKTOP_DETAIL_INFO_ARCHIVIAZIONE_SCIA_TITLE:
    'Comunicazione di archiviazione pratica',
  DETAIL_INFO_ARCHIVIAZIONE_SCIA: 'La pratica è stata archiviata',
  CONFORMAZIONE_SOSPENSIONE_TITLE: 'Conformazione sospensione',
  CONFORMAZIONE_SOSPENSIONE_LONG_TEXT:
    'Comunica che a causa della valutazione negativa da parte degli enti preposti relativa al procedimento è stata avviata la fase di divieto prosecuzione attività, richiedi i documenti mancanti per far continuare la pratica.',
  CONFORMAZIONE_SOSPENSIONE_CLASS_TITOLO_SELECT:
    'Classificazione/fascicolazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_RICHIESTA_CONFORMAZIONE_SOSPENSIONE_TITLE:
    'Comunicazione di richiesta conformazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_RICHIESTA_CONFORMAZIONE_SOSPENSIONE_LONG_TEXT:
    'Sospensione della pratica per permettere integrazione',
  DESKTOP_BPM_UPLOAD_CONFORMAZIONE_SOSPENSIONE_TITLE: 'Inserisci integrazione',
  DESKTOP_BPM_UPLOAD_CONFORMAZIONE_SOSPENSIONE_LONG_TEXT:
    'Inserisci documenti richiesti in seguito alla conformazione sospensione',
  SINGLE_SELECT_CONFORMAZIONE_SOSPENSIONE_TITLE: 'Verifica integrazione',
  SINGLE_SELECT_CONFORMAZIONE_SOSPENSIONE_LONG_TEXT:
    'Verifica integrazione in seguito alla richiesta di documentazione per conformazione',
  SINGLE_SELECT_CONFORMAZIONE_SOSPENSIONE_FORM_LABEL:
    'Esito verifica integrazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_CONFORMAZIONE_SOSPENSIONE_TITLE:
    'Verifica integrazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_CONFORMAZIONE_SOSPENSIONE_LONG_TEXT:
    'Verifica integrazione in seguito alla richiesta di documentazione per conformazione',
  DESKTOP_PRATICA_DETAIL_QUADRI_VERIFICA_CONFORMAZIONE_SOSPENSIONE_FORM_LABEL:
    'Esito verifica integrazione',
  DESKTOP_BPM_STEP_URL_COMUNICAZIONE_REVOCA_SOSPENSIONE: 'comunicazione',
  DESKTOP_BPM_STEP_NAME_COMUNICAZIONE_REVOCA_SOSPENSIONE:
    'Comunicazione di revoca sospensione',
  DESKTOP_BPM_TASK_NAME_BUTTON_REVOCA_SOSPENSIONE: 'Revoca sospensione',
  COM_REVOCA_SOSPENSIONE_TITLE: 'Comunicazione di revoca sospensione',
  COM_REVOCA_SOSPENSIONE_LONG_TEXT:
    "Comunica la revoca della sospensione in quanto l'integrazione effettuata ha dato esito positivo alla verifica integrazione",
  TASK_NEXT_BUTTON_REVOCA_SOSPENSIONE: 'Revoca la sospensione',
  COM_REVOCA_SOSPENSIONE_CLASS_TITOLO_SELECT: 'Classificazione/fascicolazione',
  DESKTOP_PRATICA_DETAIL_TASK_REVOCA_SOSPENSIONE: 'Revoca sospensione',
  DESKTOP_PRATICA_DETAIL_QUADRI_REVOCA_SOSPENSIONE_TITLE:
    'Comunicazione revoca sospensione',
  DESKTOP_PRATICA_DETAIL_QUADRI_REVOCA_SOSPENSIONE_LONG_TEXT:
    'Comunicazione revoca sospensione testo',
  TASK_NEXT_BUTTON_RICHIESTA_RIMBORSO_DESC:
    'Richiedi rimborso per una pratica conclusa negativamente',
  TASK_NEXT_BUTTON_RICHIESTA_RIMBORSO: 'Richiedi rimborso',
  DESKTOP_BPM_STEP_URL_RIMBORSO: 'dati',
  DESKTOP_BPM_STEP_NAME_RIMBORSO: 'Inserimento dati',
  DESKTOP_BPM_TASK_NAME_BUTTON_RICHIESTA_RIMBORSO: 'Richiedi rimborso',
  DESKTOP_PRATICA_DETAIL_TASK_RICHIESTA_RIMBORSO: 'Richiedi rimborso',
  NOT_SINGLE_TEXTAREA_OGGETTO_CHIUSURA_PRATICA:
    'Non è stata aggiunta nessuna nota',
  TIPO_SOCIETA_PER_AZIONI: 'Società per azioni',
  TIPO_SOCIETA_RESPONSABILITA_LIMITATA: 'Società a responsabilità limitata',

  TIPO_AZIENDA_AUTOTRASPORTI: 'Azienda autotrasporti',
  TIPO_AZIENDA_PORTUALE: 'Azienda portuale',

  DESKTOP_PRATICA_DETAIL_QUADRI_COMUNICAZIONE_NUOVO_RESPONSABILE_TITLE:
    'Comunicazione del nuovo responsabile',
  DESKTOP_PRATICA_DETAIL_QUADRI_COMUNICAZIONE_NUOVO_RESPONSABILE_LONG_TEXT:
    'Comunicazione del nuovo responsabile',
};
