import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CheckboxInput } from '../../../models/inputs/checkbox-input';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-checkbox-input',
  template: `
    <div class="form-check">
      <input
        *ngIf="control; else noControl"
        id="{{ 'input' + name }}"
        type="checkbox"
        [formControl]="control"
      />
      <ng-template #noControl>
        <input
          id="{{ 'input' + name }}"
          type="checkbox"
          [checked]="checked"
          [required]="required"
          [indeterminate]="indeterminate"
          (change)="change.emit($event)"
        />
      </ng-template>
      <label
        [ngClass]="bold ? 'font-weight-bold' : ''"
        [appColorCheckStatus]="
          control?.colorStatus || (!checked && required ? 'danger' : 'basic')
        "
        for="{{ 'input' + name }}"
        >{{ control?.label || label | translate }}</label
      >
    </div>
  `,
})
export class CheckboxInputComponent extends BaseInputComponent {
  @Input() name: string;
  @Input() control?: CheckboxInput;
  @Input() checked: boolean;
  @Input() required: boolean;
  @Input() bold: boolean;
  @Input() indeterminate: boolean;
  @Input() label = '';

  // tslint:disable-next-line: no-output-native
  @Output() change: EventEmitter<any> = new EventEmitter();
}
