export class BootstrapSize {
  is: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;

  constructor(
    is: string = '12',
    sm: string = '12',
    md: string = '12',
    lg: string = '12',
    xl: string = '12'
  ) {
    this.is = is;
    this.sm = sm;
    this.md = md;
    this.lg = lg;
    this.xl = xl;
  }

  static formPipe(pipe: string): BootstrapSize {
    const sizes: string[] = pipe.split('|');

    return new BootstrapSize(
      sizes[0] || '12',
      sizes[1] || '12',
      sizes[2] || '12',
      sizes[3] || '12',
      sizes[4] || '12'
    );
  }

  toClass(): string {
    return `col-is-${this.is} col-sm-${this.sm} col-md-${this.md} col-lg-${this.lg} col-xl-${this.xl}`;
  }

  /* toClass(size: string): string {
    if (size === undefined) {
      return 'col-is-12 col-sm-12 col-md-12 col-lg-12';
    } else {
      const sizes: string[] = size.split('|');

      let str = '';

      if (sizes[0]) {
        str += `col-is-${sizes[0]}`;
      }

      if (sizes[1]) {
        str += ` col-sm-${sizes[1]}`;
      }

      if (sizes[2]) {
        str += ` col-md-${sizes[2]}`;
      }

      if (sizes[3]) {
        str += ` col-lg-${sizes[3]}`;
      }

      if (sizes[4]) {
        str += ` col-xl-${sizes[4]}`;
      }

      return str;
    }
  } */
}
