import { Component } from '@angular/core';
import { ActionsFooterService } from './actions-footer.service';

@Component({
  selector: 'app-actions-footer',
  styleUrls: ['./actions-footer.component.scss'],
  templateUrl: './actions-footer.component.html',
})
export class ActionsFooterComponent {
  positions = ['left', 'center', 'right'];

  constructor(public actionsFooterService: ActionsFooterService) {}
}
