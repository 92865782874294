import { ApiFilterRequest } from '../api-filter-request';

export class ApiArrayFilterRequest extends ApiFilterRequest {
  operator = 'ARRAY';
  value: any[];

  constructor(options: Omit<ApiArrayFilterRequest, 'operator'>) {
    super(options);
  }
}
