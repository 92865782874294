import { Observable } from 'rxjs';
import { BaseInput, BaseInputConstructor } from './base-input';
import { LabelValue } from 'src/app/core/backend';

export interface SelectOption<T = string, E = string>
  extends LabelValue<T, E> {}

export interface SelectInputConstructor<T, E> extends BaseInputConstructor<T> {
  options: Observable<SelectOption<T, E>[]>;
}

export class SelectInput<T = string, E = string> extends BaseInput<T> {
  inputType = 'select';
  options: Observable<SelectOption<T, E>[]>;

  constructor(options: SelectInputConstructor<T, E>) {
    super(options);
    this.options = options.options;
  }
}
