import { Pipe, PipeTransform } from '@angular/core';
import { SimpleDataSource } from 'src/app/shared/table/models/simple-data-source';
import { of } from 'rxjs';

@Pipe({ name: 'simpleDataSourcePipe' })
export class SimpleDataSourcePipe implements PipeTransform {
  transform(row: any[]): SimpleDataSource<any> {
    return new SimpleDataSource({
      observable: () => of(row),
    });
  }
}
