import { I18nService } from '@eng-ds/translate';
import { Observable, of, throwError } from 'rxjs';
import { ApiResponseError } from '../backend';
import { AutoUnsubscribe } from './auto-unsubscribe.component';
declare const AgidWebComponents: any;
declare const Adsp: any;

export class BaseComponent extends AutoUnsubscribe {
  constructor(protected i18n: I18nService) {
    super();
  }

  protected _controlTranslate(error: ApiResponseError): string {
    if (
      this.i18n.translate(`ERROR_${error.keyErrorMessage}`) ===
      `ERROR_${error.keyErrorMessage}`
    ) {
      return this.i18n.translate('ERROR_GENERIC_MESSAGE');
    }
    return this.i18n.translate(`ERROR_${error.keyErrorMessage}`);
  }

  protected _handleErrorMessage(
    error: ApiResponseError,
    throwErr: boolean = false,
    position: string = 'right'
  ): Observable<any> {
    AgidWebComponents.notifications.error(
      this.i18n.translate('ERROR_GENERIC_TITLE'),
      this._controlTranslate(error),
      { position }
    );
    if (throwErr) {
      Adsp.loading.hide();
      return throwError(error);
    }
    return of(null);
  }
}
