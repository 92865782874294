import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

declare const Adsp: any;

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private oauthService: OAuthService) {}

  canActivate(): boolean {
    return this._check();
  }

  private _check() {
    if (
      this.oauthService.hasValidAccessToken() &&
      this.oauthService.hasValidIdToken()
    ) {
      return true;
    } else {
      Adsp.events.auth.emitUrlToStore(window.location.pathname);
      Adsp.events.auth.emitTokenNotValid();
      return false;
    }
  }
}
