import { Component, Input } from '@angular/core';
import { BaseInput } from '../../models';

@Component({
  selector: 'app-input-label',
  template: `
    <label
      for="{{ 'input' + name }}"
      class="label font-size-16"
      [class.font-weight-bold]="field.required"
      [class.font-weight-normal]="!field.required"
      *ngIf="
        field.label &&
        field.inputType !== 'checkbox' &&
        field.inputType !== 'fieldset'
      "
      >{{ field.label | translate }}
      <span *ngIf="!field.required && !filter" class="font-weight-light">
        ({{ 'UTILS_FORM_LABEL_NOT_MANDATORY' | translate }})
      </span></label
    >
  `,
})
export class InputLabelComponent {
  @Input() name: string;
  @Input() field: BaseInput;
  @Input() filter: boolean;
}
