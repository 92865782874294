<form [formGroup]="form" (submit)="save.emit(form.value)">
  <app-form-inputs
    [filter]="form.filter"
    [controls]="form.controls"
    [form]="form"
  ></app-form-inputs>

  <agid-button
    *ngIf="showFooter()"
    class="float-right"
    size="small"
    (click)="save.emit(form.value)"
    [disabled]="!form.valid"
    >{{ form?.footer.submit.text | translate }}</agid-button
  >

  <agid-button
    *ngIf="showFooter() && showClearButton()"
    class="float-right mr-4"
    size="small"
    outline
    (click)="resetForm()"
    >{{ form?.footer.clear.text | translate }}</agid-button
  >

  <input #htmlInputSubmit type="submit" style="display: none;" />
</form>
