import { Directive, HostBinding, Input } from '@angular/core';
export declare type NbComponentCheckStatus =
  | 'basic'
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'info'
  | 'control';

@Directive({
  selector: '[appColorCheckStatus]',
})
export class ColorStatusCheckDirective {
  @HostBinding('class.color-status-primary-check')
  get primary() {
    return this.appColorCheckStatus === 'primary';
  }

  @HostBinding('class.color-status-info-check')
  get info() {
    return this.appColorCheckStatus === 'info';
  }

  @HostBinding('class.color-status-success-check')
  get success() {
    return this.appColorCheckStatus === 'success';
  }

  @HostBinding('class.color-status-warning-check')
  get warning() {
    return this.appColorCheckStatus === 'warning';
  }

  @HostBinding('class.color-status-danger-check')
  get danger() {
    return this.appColorCheckStatus === 'danger';
  }

  @HostBinding('class.color-status-basic-check')
  get basic() {
    return this.appColorCheckStatus === 'basic';
  }

  @HostBinding('class.color-status-control-check')
  get control() {
    return this.appColorCheckStatus === 'control';
  }

  @Input() appColorCheckStatus: NbComponentCheckStatus;
}
