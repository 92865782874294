import { Component, Input } from '@angular/core';
import { RadiosInput } from '../../../models/inputs/radios-input';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-radios-input',
  template: `
    <div [class.row]="control.inline" *ngIf="control">
      <div *ngFor="let option of control.options | async">
        <div class="form-check" [class.form-check-inline]="control.inline">
          <input
            [formControl]="control"
            [value]="option.value"
            [checked]="option.value === control.value"
            [id]="option.value"
            [readOnly]="control.readonly"
            type="radio"
          />
          <label
            class="font-size-16 radio"
            [for]="option.value"
            [appColorCheckStatus]="control.colorStatus"
          >
            {{ option.label | translate }}</label
          >
        </div>
      </div>
    </div>
  `,
})
export class RadiosInputComponent extends BaseInputComponent {
  @Input() name: string;
  @Input() control: RadiosInput;
}

/**
 * <nb-radio-group
      [status]="control.colorStatus"
      [formControl]="control"

    >
      <nb-radio
        *ngFor="let option of control.options | async"
        [value]="option.value"
        [class.inline]="control.inline"
      >
        {{ option.label | translate }}
      </nb-radio>
    </nb-radio-group>

    <button
      *ngIf="control.clearable"
      type="button"
      nbButton
      status="basic"
      size="small"
      (click)="control.reset()"
    >
      <nb-icon icon="close-outline"></nb-icon> {{ 'UTILS.CLEAR' | translate }}
    </button>
 */
