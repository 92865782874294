import { FileType } from '../../enum';
import { InputStatus, CheckFn } from './input-status';
import { StatusMessage } from './status-message';

/* tslint:disable:semicolon */
export class ValidationErrorStatus {
  public static FILE_TYPE = (types: FileType[]) => {
    return InputStatus.create(
      (control) => control.hasError('FILE_NOT_VALID'),
      'danger',
      {
        text: 'VALIDATIONS_FILE_EXTENSION',
      }
    );
  };

  public static YEAR_DATE = () => {
    return InputStatus.create(
      (control) => control.hasError('year_date'),
      'danger',
      {
        text: 'VALIDATIONS_ERROR_YEAR',
      }
    );
  };

  /**
   * TODO control.touched doesn't work with file input, ask fede a workaround
   * now it fires immediatly the error
   */
  public static FILE_REQUIRED = () => {
    return InputStatus.create(
      (control) => control.hasError('required'),
      'danger',
      {
        text: 'VALIDATIONS_REQUIRED',
      }
    );
  };

  public static FILE_DIMENSION = () => {
    return InputStatus.create(
      (control) => control.hasError('file_dimension'),
      'danger',
      {
        text: 'VALIDATIONS_FILE_DIMENSION_NOT_VALID',
      }
    );
  };

  private static create = (error: string, message?: StatusMessage) =>
    InputStatus.create(
      (control) => control.touched && control.hasError(error),
      'danger',
      message
    );

  public static CUSTOM = (checkFn: CheckFn, message?: StatusMessage) =>
    InputStatus.create(checkFn, 'danger', message);

  public static REQUIRED_WITH_MESSAGE = (message?: StatusMessage) =>
    ValidationErrorStatus.create('required', message);

  // tslint:disable-next-line: member-ordering
  public static REQUIRED = ValidationErrorStatus.REQUIRED_WITH_MESSAGE({
    text: 'VALIDATIONS_REQUIRED',
  });
}
