import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';

declare type NbComponentStatus =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'light'
  | 'white';

export interface ActionFooter {
  label: string;
  class?: string[];
  position: 'left' | 'center' | 'right';
  outline?: boolean;
  disabled?: Observable<boolean>;
  status?: NbComponentStatus;
  click: () => void;
  icon?:
    | {
        icon: string;
        svgSpritePath?: string;
        position?: 'left' | 'right';
        size?: 'extra-small' | 'small' | 'large' | 'extra-large';
        color?: NbComponentStatus;
      }
    | string;
}

@Injectable()
export class ActionsFooterService {
  actions: BehaviorSubject<ActionFooter[]> = new BehaviorSubject([]);
  actions$ = this.actions.asObservable();

  add(actions: ActionFooter[]): void {
    this.actions.next([...actions]);
  }

  destroy(): void {
    this.actions.next([]);
  }
}
