import { Pipe, PipeTransform } from '@angular/core';
import { ActionFooter } from './actions-footer.service';

@Pipe({ name: 'appActionsFooterFilter' })
export class ActionsFooterFilterPipe implements PipeTransform {
  transform(
    actions: ActionFooter[],
    position: ActionFooter['position']
  ): ActionFooter[] {
    return actions && actions.filter((a) => a.position === position);
  }
}
