import { ApiConfig, BackendConfig } from '@eng-ds/api-client';

declare const Adsp: any;

const apiGeneralPath = '/apigeneral/1.0';
const apiScrivaniaPath = '/apiscrivania/1.0';
const apiMguPath = '/apimgu/1.0';
const apiBpmPath = '/apiscrivania/1.0';
const mockupUrl = 'http://localhost:3000';
const rockUrl = 'https://5fa343594a16.ngrok.io/apiscrivania';

const backendApi: ApiConfig[] = [
  {
    name: 'getTranslate',
    method: 'GET',
    url:
      Adsp.configs.get('appHost') +
      '/general_lingua/{languageCode}/{module}.json',
  },
  {
    name: 'getNazioni',
    method: 'GET',
    url: apiGeneralPath + '/config/nazioni',
  },
  {
    name: 'searchDotazioneTecnica',
    method: 'GET',
    url: apiScrivaniaPath + '/dotazionetecnica/search',
  },
  {
    name: 'esitiVerifica',
    method: 'GET',
    url: apiScrivaniaPath + '/config/staticList/esitoVerifica',
  },
  {
    name: 'getListaDotazioniTecnichePratica',
    method: 'GET',
    url: apiScrivaniaPath + '/dotazionetecnica/{uuidPratica}/list',
  },
  {
    name: 'deleteDotazioneTecnicaPratica',
    method: 'DELETE',
    url:
      apiScrivaniaPath +
      '/dotazionetecnica/{uuidPratica}/associazione/{uuidDotazioneTecnica}',
  },
  {
    name: 'detailDotazioneTecnicaPratica',
    method: 'GET',
    url:
      apiScrivaniaPath + '/dotazionetecnica/{uuidPratica}/associazione/{uuid}',
  },
  {
    name: 'updateDotazioneTecnica',
    method: 'PATCH',
    url: apiScrivaniaPath + '/dotazionetecnica/{uuid}',
  },
  {
    name: 'createDotazioneTecnica',
    method: 'POST',
    url: apiScrivaniaPath + '/dotazionetecnica',
  },
  {
    name: 'getProvince',
    method: 'GET',
    url: apiGeneralPath + '/config/province',
  },
  {
    name: 'getComuni',
    method: 'GET',
    url: apiGeneralPath + '/config/comuni/{uuid}',
  },
  {
    name: 'getListUtentiPratica',
    method: 'GET',
    url:
      // mockupUrl+
      apiScrivaniaPath + '/pratica/{uuidPratica}/associaUtenti',
  },
  {
    name: 'getDocumentByPratica',
    method: 'GET',
    url:
      // apiScrivaniaPath +
      apiBpmPath + '/documenti/{uuidPratica}/allegati',
  },
  {
    name: 'caricaDocumentoPratica',
    method: 'POST',
    url:
      // apiScrivaniaPath +
      apiBpmPath + '/documenti/{uuidPratica}/upload',
  },
  {
    name: 'scaricaDocumentoPratica',
    method: 'GET',
    url:
      // apiScrivaniaPath +
      apiBpmPath + '/documenti/{uuidPratica}/download/{uuidDocumento}',
  },
  {
    name: 'deleteDocumentoPratica',
    method: 'DELETE',
    url: apiScrivaniaPath + '/documenti/{uuidPratica}/delete/{uuidDocumento}',
  },
  {
    name: 'getUfficiCompetenti',
    method: 'GET',
    url:
      // mockupUrl +
      apiScrivaniaPath + '/pratica/{uuidPratica}/ufficiCompetenti',
  },
  {
    name: 'getRichiestaAccesso',
    method: 'GET',
    url: apiBpmPath + '/pratica/{uuidPratica}/richiestaAccesso',
  },
  {
    name: 'postRichiestaAccesso',
    method: 'POST',
    url: apiScrivaniaPath + '/pratica/{uuidPratica}/richiestaAccesso',
  },
  {
    name: 'deleteRichiesta',
    method: 'DELETE',
    url:
      apiScrivaniaPath +
      '/pratica/{uuidPratica}/richiestaAccesso/{uuidRichiesta}',
  },
  {
    name: 'getPrivacy',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/{uuidPratica}/infoPrivacy',
  },
  {
    name: 'getListDipendenti',
    method: 'GET',
    url: apiScrivaniaPath + '/personale/{uuidPratica}/list',
  },
  {
    name: 'getDipendente',
    method: 'GET',
    url: apiScrivaniaPath + '/personale/{uuid}',
  },
  {
    name: 'searchDipendente',
    method: 'GET',
    url: apiScrivaniaPath + '/personale/search',
  },
  {
    name: 'addDipendente',
    method: 'PATCH',
    url: apiScrivaniaPath + '/personale/{uuidPersonale}',
  },
  {
    name: 'deleteDipendente',
    method: 'DELETE',
    url: apiScrivaniaPath + '/personale/{uuid}',
  },
  {
    name: 'updateDipendente',
    method: 'PATCH',
    url: apiScrivaniaPath + '/personale/{uuid}',
  },
  {
    name: 'addNewDipendente',
    method: 'POST',
    url: apiScrivaniaPath + '/personale',
  },
  {
    name: 'saveStep',
    method: 'POST',
    url:
      apiBpmPath +
      '/praticaBPM/savestep/{uuidPratica}/{uuidProcTask}/{uuidProcTaskStep}',
  },
  {
    name: 'saveTask',
    method: 'POST',
    url: apiBpmPath + '/praticaBPM/saveTask/{uuidPratica}/{uuidTask}',
  },
  {
    name: 'saveFormApplicant',
    method: 'POST',
    url: apiBpmPath + '/praticaBPM/saveFormApplicant',
  },
  {
    name: 'getNextTask',
    method: 'GET',
    url: apiBpmPath + '/praticaBPM/nexttask/{uuidPratica}',
    // url: mockupUrl + apiBpmPath + '/praticaBPM/nexttask/{uuidPratica}',
  },
  {
    name: 'getTaskInfo',
    method: 'GET',
    url:
      // mockupUrl +
      apiBpmPath + '/praticaBPM/taskinfo/{uuidPratica}/{uuidProcTask}',
  },
  {
    name: 'getStepInfo',
    method: 'GET',
    url:
      // mockupUrl +
      apiBpmPath +
      '/praticaBPM/stepinfo/{uuidPratica}/{uuidProcTask}/{uuidProcTaskStep}',
  },
  {
    name: 'getQuadriDataInfo',
    method: 'GET',
    url:
      // mockupUrl +
      apiBpmPath +
      '/praticaBPM/stepdatainfo/{uuidPratica}/{uuidProcTask}/{uuidProcTaskStep}',
  },
  {
    name: 'getRichiedenteByPratica',
    method: 'GET',
    url:
      // mockupUrl +
      apiBpmPath + '/pratica/{uuidPratica}/richiedente',
  },
  {
    name: 'getDeleganteByPratica',
    method: 'GET',
    url:
      // mockupUrl +
      apiBpmPath + '/pratica/{uuidPratica}/delegante',
  },
  {
    name: 'getSezioniAmministrazione',
    method: 'GET',
    url: apiScrivaniaPath + '/config/staticList/sezioniAmministrazione',
  },
  {
    name: 'getStatiDelega',
    method: 'GET',
    url: apiScrivaniaPath + '/config/staticList/statoDelega',
  },
  {
    name: 'getDelegatiAssociatiByPratica',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/{uuidPratica}/delegatiAssociati',
  },
  {
    name: 'modificaStatoDelega',
    method: 'PATCH',
    url: apiMguPath + '/delega/procedimento/{uuid}/stato',
  },
  {
    name: 'getOggettoDataInfo',
    method: 'GET',
    url:
      apiBpmPath +
      '/praticaBPM/oggettodatainfo/{uuidPratica}/{uuidProcTask}/{uuidProcTaskStep}/{uuidQuadro}/{chiaveOggetto}',
  },
  {
    name: 'getRegistroAccessi',
    method: 'GET',
    url: apiScrivaniaPath + '/registroaccessi/pratica/{uuidPratica}',
  },
  {
    name: 'getPraticaDetail',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/{uuid}',
  },
  {
    name: 'downloadTemplate',
    method: 'GET',
    url:
      // apiScrivaniaPath +
      apiBpmPath + '/documenti/{uuidPratica}/downloadTemplate/{uuidDocumento}',
  },
  {
    name: 'generaTemplate',
    method: 'GET',
    url:
      // apiScrivaniaPath +
      apiBpmPath +
      '/documenti/{uuidPratica}/downloadGenerated/{uuidTask}/{tipoDocumento}',
  },

  {
    name: 'getComunicazioneAccessoDettaglio',
    method: 'GET',
    url:
      apiScrivaniaPath +
      '/pratica/{uuidPratica}/comunicazioneAccesso/{uuidComAccesso}',
  },

  {
    name: 'creaComunicazioneLink',
    method: 'POST',
    url:
      apiScrivaniaPath +
      '/pratica/{uuid}/creaComunicazioneLink/{uuidRichiesta}',
  },

  {
    // for pratica detail
    name: 'getTaskDetail',
    method: 'GET',
    url:
      /* mockupUrl +  */ apiScrivaniaPath +
      '/praticaBPM/getTaskDetail/{uuidPratica}',
  },
  {
    // for pratica detail
    name: 'quadriDataValues',
    method: 'GET',
    url:
      /*  mockupUrl + */
      apiScrivaniaPath +
      '/praticaBPM/getDataDetail/{uuidPratica}/{uuidProcTask}',
  },

  {
    // for pratica detail
    name: 'getRegistroAccessi',
    method: 'GET',
    url: apiScrivaniaPath + '/registroaccessi/pratica/{uuidPratica}',
  },
  {
    name: 'getListaUtentiOpere',
    method: 'GET',
    url:
      // mockupUrl+
      apiScrivaniaPath + '/pratica/{uuidPratica}/utentiOpere',
  },
  {
    name: 'getListaUtentiAssociati',
    method: 'GET',
    url:
      apiScrivaniaPath + '/pratica/{uuidPratica}/utentiAssociati',
  },
  {
    name: 'getDetailDelega',
    method: 'GET',
    url: apiMguPath + '/delega/{uuid}',
  },
  {
    name: 'getAllegatiDelega',
    method: 'GET',
    url: apiScrivaniaPath + '/deleghe/{uuid}/documenti',
  },
  {
    name: 'downloadDelegaDocument',
    method: 'GET',
    url: apiScrivaniaPath + '/deleghe/{uuidDelega}/documenti/{uuidDocumento}',
  },
  {
    name: 'getAmbiti',
    method: 'GET',
    url: apiScrivaniaPath + '/ambito/{uuid}',
  },
  {
    name: 'getInfoProcText',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/{uuidPratica}/infoProcText',
  },
  {
    name: 'associaUtentiPratica',
    method: 'POST',
    url: apiScrivaniaPath + '/pratica/{uuidPratica}/associaUtentiPratica',
  },
  {
    name: 'downloadTemplateGenerated',
    method: 'GET',
    url:
      apiScrivaniaPath +
      '/documenti/{uuidPratica}/downloadGenerated/{uuidProcedimentoTask}/{tipoDocumento}/{useAllValues}',
  },
  {
    name: 'downloadTemplatePre',
    method: 'GET',
    url:
      // apiScrivaniaPath +
      apiBpmPath +
      '/documenti/{uuidPratica}/downloadTemplatePre/{uuidDocumento}',
  },
  {
    name: 'getListAmbiti',
    method: 'GET',
    url: apiScrivaniaPath + '/ambito/getAmbitiPratica/{uuidPratica}',
  },
  {
    name: 'setAttivitaScia',
    method: 'POST',
    url: apiScrivaniaPath + '/ambito/saveAmbitoPratica/{uuidPratica}',
  },
  {
    name: 'getListDichiarazioni',
    method: 'GET',
    url:
      apiScrivaniaPath + '/dichiarazioni/getDichiarazioniPratica/{uuidPratica}',
  },
  {
    name: 'setDichiarazioni',
    method: 'POST',
    url:
      apiScrivaniaPath +
      '/dichiarazioni/saveDichiarazionePratica/{uuidPratica}',
  },
  {
    name: 'getListAttivitaAssociate',
    method: 'GET',
    url: apiScrivaniaPath + '/ambito/ambitiPraticaDettaglio/{uuidPratica}',
  },
  {
    name: 'getAmbitiPraticaDettaglio',
    method: 'GET',
    url: apiScrivaniaPath + '/ambito/ambitiPraticaDettaglio/{uuidPratica}',
  },
  {
    name: 'getListAutodichiarazioniAssociate',
    method: 'GET',
    url:
      apiScrivaniaPath + '/dichiarazioni/getDichiarazioniPratica/{uuidPratica}',
  },
  {
    name: 'getTipoVeicoliList',
    method: 'GET',
    url: apiScrivaniaPath + '/config/staticList/tipologiaVeicolo',
  },
  {
    name: 'getTipoDocumentoList',
    method: 'GET',
    url: apiScrivaniaPath + '/config/staticList/tipoDocumento',
  },
  {
    name: 'getMansioniList',
    method: 'GET',
    url: apiScrivaniaPath + '/config/staticList/tipologiaMansioni',
  },
  {
    name: 'getTipologiaContrattoList',
    method: 'GET',
    url: apiScrivaniaPath + '/config/staticList/tipologiaContratto',
  },
  {
    name: 'getEntiEsterni',
    method: 'GET',
    url: apiScrivaniaPath + '/organizzazione/entiEsterni',
  },
  {
    name: 'detailComuEntiEsterni',
    method: 'GET',
    url:
      apiScrivaniaPath + '/comunicazioni/entiEsterni/{uuidPratica}/{uuidCom}',
  },
  {
    name: 'richiediParereAEnte',
    method: 'POST',
    url:
      apiScrivaniaPath +
      '/comunicazioni/entiEsterni/{uuidPratica}/{uuidTask}/inviaAEnte',
  },
  {
    name: 'listRichiesteParereInviate',
    method: 'GET',
    url:
      apiScrivaniaPath +
      '/comunicazioni/entiEsterni/{uuidPratica}/{uuidTask}/list',
  },
  {
    name: 'listRichiesteParereRicevute',
    method: 'GET',
    url:
      apiScrivaniaPath +
      '/comunicazioni/entiEsterni/{uuidPratica}/{uuidTask}/aEnte',
  },
  {
    name: 'inviaEsitoDaEnte',
    method: 'POST',
    url:
      apiScrivaniaPath +
      '/comunicazioni/entiEsterni/{uuidPratica}/{uuidTask}/inviaDaEnte/{uuidRichiesta}',
  },
  {
    name: 'listComunicazioniTrasversali',
    method: 'GET',
    url: apiScrivaniaPath + '/comunicazionitrasversali/list',
  },
  {
    name: 'getComunicazioneTrasversaleDettaglio',
    method: 'GET',
    url: apiScrivaniaPath + '/comunicazionitrasversali/{uuid}',
  },
  {
    name: 'creaComunicazioneTrasversale',
    method: 'POST',
    url:
      apiScrivaniaPath + '/comunicazionitrasversali/{uuidPratica}/{uuidTask}',
  },
  {
    name: 'comunicazioneVisualizzata',
    method: 'PATCH',
    url: apiScrivaniaPath + '/comunicazionitrasversali/{uuid}/visualizza',
  },
  {
    name: 'getPagamenti',
    method: 'GET',
    url: apiScrivaniaPath + '/pagamento/list',
  },
  {
    name: 'calcoloIUV',
    method: 'GET',
    url: apiScrivaniaPath + '/pagamento/inviaPosizione/{uuidPagamento}',
  },
  {
    name: 'saveRegistroImprese',
    method: 'POST',
    url: apiScrivaniaPath + '/registroimprese/{uuidPratica}',
  },
  {
    name: 'getListaUtentiAssociati',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/{uuidPratica}/utentiAssociati',
  },
];

export const backendConfig: Omit<BackendConfig, 'getApiConfig' | 'prepareUrl'> =
  {
    baseUrl: Adsp.configs.get('baseApiUrl'),
    api: backendApi,
  };
