import { TranslateService } from '@eng-ds/translate';

export class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
    super('');
  }

  toString() {
    return this.service.currentLang;
  }
}
