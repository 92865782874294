import { Injectable } from '@angular/core';
import { ApiClient } from '@eng-ds/api-client';
import { TranslateService as EngTranslateService } from '@eng-ds/translate';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AutoUnsubscribe } from '../components';
import { Language } from '../models/language';

declare const Adsp: any;

const MODULE = 'bpm';

interface JsonTranslate {
  [x: string]: string;
}

@Injectable()
export class TranslateService extends AutoUnsubscribe {
  isTranslationsLoad: boolean = false;
  isTranslationOnError: boolean = false;
  constructor(
    private apiClient: ApiClient,
    private translateService: EngTranslateService
  ) {
    super();
    this._subscribeOnLanguageChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  waitUntil() {
    const prom = (resolve) => {
      if (this.isTranslationsLoad || this.isTranslationOnError)
        return resolve(this.isTranslationsLoad);
      else setTimeout((_) => prom(resolve), 500);
    };
    return new Promise(prom);
  }

  isTranslation(): Promise<boolean> {
    return new Promise(async (resolve, reject) =>
      resolve(!!(await this.waitUntil()))
    );
  }

  private _getJsonTranslate(
    module: string,
    code: string
  ): Observable<JsonTranslate> {
    return this.apiClient.request<JsonTranslate>('getTranslate', null, null, {
      languageCode: code.toUpperCase(),
      module: module,
    });
  }

  // fetch delle traduzioni
  private _fetchTranslate(code: string): Observable<any> {
    return forkJoin([
      this._getJsonTranslate(MODULE, code),
      this._getJsonTranslate('root', code),
    ]).pipe(
      tap((result: JsonTranslate[]) => {
        // set delle traduzioni
        this.translateService.setTranslation(code, {
          ...result[0],
          ...result[1],
        });
        this.isTranslationsLoad = true;
      }),
      catchError((e) => {
        this.isTranslationOnError = true;
        return of({});
      })
    );
  }

  private _subscribeOnLanguageChange(): Observable<any> {
    return (Adsp.events.header.activeLanguage$ as Observable<Language>).pipe(
      switchMap((lang) =>
        // fetch del json delle traduzioni
        this._fetchTranslate(lang.codice).pipe(
          tap(() => {
            // set del codice lingua sulle traduzioni
            this.translateService.use(lang.codice);
          })
        )
      )
    );
  }
}
