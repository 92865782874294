import { ApiFilterRequest } from '../api-filter-request';

export class ApiBetweenFilterRequest extends ApiFilterRequest {
  operator = 'BETWEEN';
  value: { start: string; end: string };

  constructor(options: Omit<ApiBetweenFilterRequest, 'operator'>) {
    super(options);
  }
}
