import { Observable } from 'rxjs';
import { BaseInput, BaseInputConstructor } from './base-input';
import { SelectOption } from './select-input';

export interface RadioOption<T = string, E = string>
  extends SelectOption<T, E> {}

export interface RadiosInputConstructor<T, E>
  extends Omit<BaseInputConstructor<T>, 'placeholder' | 'pattern'> {
  options: Observable<RadioOption<T, E>[]>;
  inline?: boolean;
}

export class RadiosInput<T = string, E = string> extends BaseInput<T> {
  inputType = 'radios';
  options: Observable<RadioOption<T, E>[]>;
  inline?: boolean;

  constructor(options: RadiosInputConstructor<T, E>) {
    super(options);
    this.options = options.options;

    if (options.inline) {
      this.inline = options.inline;
    }
  }
}
