import { Component, OnDestroy } from '@angular/core';
import { I18nService } from '@eng-ds/translate';
import { AutoUnsubscribe } from './core/components';
import { TranslateService } from './core/services/translate.service';

@Component({
  selector: 'adsp-bpm-root',
  templateUrl: './app.component.html',
})
export class AppComponent extends AutoUnsubscribe implements OnDestroy {
  constructor(i18nService: I18nService, translateService: TranslateService) {
    super();
  }
}
