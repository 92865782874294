import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { TranslateModule } from '@eng-ds/translate';
import { ActionsFooterFilterPipe } from './actions-footer/action-footer-filter.pipe';
import { ActionsFooterComponent } from './actions-footer/actions-footer.component';
import { ActionsFooterService } from './actions-footer/actions-footer.service';

@NgModule({
  imports: [CommonModule, TranslateModule.forChild()],
  declarations: [ActionsFooterComponent, ActionsFooterFilterPipe],
  exports: [ActionsFooterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ActionsFooterService,
        // LoadingService
      ],
    };
  }
}
