<div class="row">
  <div class="col-12">
    <div
      [ngClass]="hasFile ? 'justify-content-between' : 'justify-content-end'"
      class="row"
    >
      <div
        *ngIf="hasFile || control.placeholder"
        [ngClass]="
          size
            ? 'col-7 d-flex align-items-center'
            : 'col-9 col-sm-9 col-md-9 d-flex align-items-center'
        "
      >
        <span class="font-size-14 pl-1">
          {{ hasFile ? file?.name : (control.placeholder | translate) }}
        </span>
      </div>
      <div [class]="size || 'col-3 col-sm-3 col-md-3'">
        <agid-button
          class="float-right"
          [outline]="outline"
          icon
          size="small"
          [color]="hasFile ? 'danger' : 'primary'"
          (click)="btnClick()"
        >
          <agid-icon
            *ngIf="!hasFile"
            class="d-inline-flex"
            icon="it-search"
            [color]="outline ? 'primary' : 'white'"
          >
          </agid-icon>
          <agid-icon
            *ngIf="hasFile"
            class="d-inline-flex"
            icon="it-delete"
            [color]="outline ? 'danger' : 'white'"
          >
          </agid-icon>
          <span style="margin-left: 0.25em" class="d-none d-md-inline">{{
            hasFile
              ? ("UTILS_REMOVE" | translate)
              : (control.buttonPlaceholder | translate)
          }}</span>
        </agid-button>

        <agid-button
          *ngIf="hasFile && control.showUploadBtn"
          class="ml-2"
          icon
          size="small"
          (click)="control.upload(file)"
        >
          <agid-icon class="d-inline-flex" icon="it-upload" color="white">
          </agid-icon>
          <span class="d-none d-md-inline">
            {{ control.labelUploadBtn | translate }}
          </span>
        </agid-button>
      </div>

      <input #fileTag type="file" class="d-none" />
    </div>
  </div>
</div>
