import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input,
  ViewChild,
} from '@angular/core';
import { DatepickerHostDirective } from '../../../directives';
import { DateInput } from '../../../models/inputs/date-input';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-date-input',
  template: `<input
    class="custom-input shape-rectangle size-large nb-transition input-full-width"
    [type]="control.type"
    [appColorStatus]="control.colorStatus"
    maxlength="10"
    [formControl]="control"
  />`,
})
export class DateInputComponent extends BaseInputComponent {
  @ViewChild(DatepickerHostDirective) datepickerHost: DatepickerHostDirective;
  @Input() control: DateInput;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }
}
