import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@eng-ds/translate';
import { SelectOption } from '../models';

@Pipe({ name: 'addClearOption' })
export class AddClearOptionPipe implements PipeTransform {
  constructor(private i18nService: I18nService) {}
  transform(options: SelectOption[], addClear: boolean): SelectOption[] {
    let optionsReturn: SelectOption[] = [];

    if (addClear) {
      optionsReturn.push({
        label: this.i18nService.translate('UTILS.SELECT.CLEAR_CHOICE'),
        value: undefined
      });
    }

    if (Array.isArray(options)) {
      optionsReturn = optionsReturn.concat(options);
    }

    return optionsReturn;
  }
}
