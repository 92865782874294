import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

declare const Adsp: any;

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(private oauthService: OAuthService) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const codiceVerticale = localStorage.getItem('codiceVerticale') || '';
    if (
      this.oauthService.hasValidAccessToken() &&
      this.oauthService.getIdToken()
    ) {
      const request: any = {
        headers: req.headers
          .set('Authorization', 'Bearer ' + this.oauthService.getAccessToken())
          .set('app-id-token', this.oauthService.getIdToken())
          .set('codiceVerticale', codiceVerticale),
      };

      if (Adsp.configs.get('auth.cookieEnabled')) {
        request.withCredentials = true;
      }

      req = req.clone(request);
    }

    return next
      .handle(req)
      .pipe(catchError((event: HttpEvent<any>) => this._handleError(event)));
  }

  private _handleError(err: HttpEvent<any>): Observable<any> {
    if (err instanceof HttpErrorResponse) {
      switch (err.status) {
        case 401:
          Adsp.events.auth.emitUrlToStore(window.location.pathname);
          Adsp.events.auth.emitApiUnauthorized(err.error);
          break;
        case 403:
          Adsp.events.auth.emitApiForbidden(err.error);
          break;
        case 500:
        case 404:
          Adsp.events.portal.emitApiMaintenance();
          break;
        case 409:
          Adsp.events.auth.emitApiUnauthorized(err.error);
          break;
      }
    }
    return throwError(err);
  }
}
